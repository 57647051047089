export const TRANSLATIONS_EN = {
    letsmake: "Let's make your business",
    moreprofit: 'More Profitable',
    createaccount: 'Create new account with Planigo',
    getstart: 'Get Started',
    support: 'Support',
    toservice: 'Terms of Service',
    ppolicy: 'Privacy Policy',
    nextgen: 'Next Generation',
    siginbtn: 'SIGN IN',
    insaletec: 'in Sale Technologies',
    sintoacc: 'Sign in to your account',
    formfield: {
        email: 'Email Address',
        pass: 'Password',
        forgetpw: 'Forget Password',
        enterpass: 'Enter Password',
    },
    noaccyet: 'Not have an account yet?',
    cnewacc: 'Create New Account',
    tagname: 'Tag Name',
    tagName: 'Tag Name',
    srchtag: 'Search Tag',
    tags: 'Tags',
    tagdetails: 'Tag Details',
    storetags: 'Store Tags',
    addtags: 'ADD TAGS',
    newtag: 'New Tag',
    newtagplace: 'Type New tag name & press enter',
    addedtags: 'Added Tags',
    srchtagname: 'Search Tag',
    searchtagplace: 'Type Searching tag name & press enter',
    home: 'Home',
    btnnames: {
        save: 'Save',
        back: 'Back',
        update: 'Update',
        delete: 'Delete',
        search: 'Search',
        addnew: 'Add New',
        upload: 'Upload',
        activate: 'Activate',
        confirm: 'CONFIRM',
        close: 'Close',
        clearall: 'Clear All',
        all: 'All',
        undo: 'Undo',
        redo: 'Redo',
        reset: 'Reset',
        testai: 'Test AI',
        propose: 'Propose',
        loadmore: 'Load More',
        yes: 'Yes',
        no: 'No',
        selectblock: 'Select Block',
        deselectblock: 'Deselect Block',
        excelexport: 'Excel',
        exporttoexcel: 'Export to Excel',
        exportprodinfo: 'Export Product Info',
        importprodinfo: 'Import Product Info',
        publish: 'Publish',
        unpublish: 'Unpublish',
        rerequest: 'Re-Request',
        download: 'Download',
        share: 'Share',
        addnewcat: 'Add New Category',
        deletedepartment: 'Delete Department',
        addnewsubcat: 'Add New Sub Category',
        deletecategory: 'Delete Category',
        updatecategory: 'Update Category',
        addnewbrand: 'Add New Brand',
        updatesubcategory: 'Update Sub Category',
        deletesubcategory: 'Delete Sub Category',
        add: 'Add',
        change: 'Change',
        restore: 'Restore',
        ConfirmedList: 'Confirmed List',
        send: 'Send',
        delete_reference_task: 'Delete future tasks',
        delete_this_task_only: 'Delete this task only',
        update_reference_task: 'Update future tasks',
        update_this_task_only: 'Update this task only',
    },
    NO_UUID: 'No UUID found',
    departmentname: 'Department Name',
    department: 'Department',
    departmentchanges: 'DEPARTMENT CHANGES',
    depcolor: 'Department Color',
    departments: 'Departments',
    selectsavedepartment: 'Select and Save Department',
    selectdepartment: 'Select Department',
    srchdep: 'Search Department',
    dunitname: 'Display Unit Name',
    srchdunit: 'Search Display Unit',
    newfield: 'New Field',
    dunits: 'Display Units',
    newfieldadd: 'New Display Unit Add',
    editdunitfield: 'Display Unit Edit',
    floorname: 'Floor Name',
    floor: 'Floor',
    floors: 'Floors',
    srchfloor: 'Search Floor',
    excelupload: 'Excel Upload',
    uom: 'uom',
    uomeasure: 'Unit of Measure',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    depth: 'Depth',
    s_height: 'S.Height',
    s_gap: 'S.Gap',
    breadth: 'Breadth',
    color: 'Color',
    row: 'Row',
    rowstructure: 'Row Structure',
    rowheights: 'Row Heights',
    rows: 'Rows',
    newrow: 'New Row',
    addnewrow: 'Add New Row',
    multiplerows: 'Multiple',
    multipleshelves: 'Multiple Shelve Rows',
    neededshelves: 'Needed Shelves',
    type: 'Type',
    shelve: 'SHELVE',
    details: 'Details',
    dndsomefileshere: 'Drag & drop some files here, or click to select files',
    addimgtorec: 'Add a image that easy to recognize fast',
    img: 'Image',
    cropimg: 'Crop Image',
    browsimg: ' Browse Image...',
    continue: 'CONTINUE',
    planoname: 'Planogram Name',
    planograms: 'Planograms',
    planogram: 'Planogram',
    deleteplanogram: 'DELETE PLANOGRAM',
    activeplanogram: 'ACTIVATE PLANOGRAM',
    searchplanogram: 'Search Planogram',
    searchstore: 'Search Store',
    selectstore: 'Select Store',
    findstore: 'Find a Store',
    newplanogram: 'New planogram',
    existingplanogram: 'Existing planogram ',
    version: 'Version',
    status: 'Status',
    PENDING_STATUS: 'PENDING',
    DRAFT: 'Draft',
    date: 'Date',
    activationdate: 'Activation Date',
    fieldtypes: 'Field Types',
    fieldtype: 'Field Type',
    allfields: 'All Fields',
    editfdetails: 'Edit Field Details',
    filteredfields: 'Filtered Fields',
    fieldid: 'ID',
    leftfield: 'Left Field',
    rightfield: 'Right Field',
    fieldno: 'Field Number',
    selctfieldno: 'Select Field Number',
    notes: 'Notes',
    viewfield: 'View Field',
    selectafloor: 'Select a Floor',
    entervdlteplano: 'Enter version no. of planogram to continue',
    newproducts: 'New products',
    products: 'Products',
    product: 'Product',
    productdetails: 'Product Details',
    productbrand: 'Product Brand',
    brand: 'Brand',
    productname: 'Product Name',
    searchproduct: 'Search product',
    existingproducts: 'Existing products',
    productslist: 'Products List',
    recentlist: 'Recent List',
    srchproduct: 'Search product',
    deleteproduct: 'Delete Product',
    deleteallproducts: 'Delete all products',
    dlteallprodmessage:
        'Are you sure to delete all products in this field view?.',
    improvement: 'IMPROVEMENT',
    expand: 'Expand',
    suretodelete: 'Are you sure you want to delete',
    confirmdlteproblock: 'Confirm to delete product block',
    suretoremoveprodshelve:
        'Are you sure to remove this product from this shelve?',
    changeplanogramstatus: 'Change planogram status',
    wanttochangelayoutstate: 'Do you wants to change layout state to ',
    planostatusupdatesuccess: 'Planogram status updated successfully',
    erroroccurred: 'error occurred',
    aitest: 'AI Test',
    preview: 'Preview',
    tree: 'Tree',
    usezoominout: 'use mouse to zoom in/out or move',
    convertjson: 'Convert JSON',
    json: 'JSON',
    invalidjson: 'Invalid JSON',
    revenue: 'Sale',
    revp: 'S%',
    revface: 'S/FACE',
    fieldp: 'Field %',
    profit: 'Profit',
    totalrevperface: 'Total Revenue Per Facing',
    pr: 'Pr %',
    overview: 'Overview',
    chainprofit: 'Chain Profit',
    storeprofit: 'Store Profit',
    convrate: 'Conv. Rate',
    clearfilters: 'Clear Filters',
    draftfielddetails: 'Draft field details ',
    saved: 'saved',
    updated: 'updated',
    succussfuly: ' Successfully',
    newdraftlayoutcreated: 'New draft layout created successfully',
    backtomngmt: 'Back to Management',
    changes: 'CHANGES',
    generaldata: 'GENERAL DATA',
    netaverage: 'Net.Avarage',
    leadingsales: 'Leading Sales',
    convdept: 'Conv.Dept',
    source: 'Source',
    barcode: 'Barcode',
    sourcedetails: 'Source Details',
    scales: 'Scales',
    focuson: 'FOCUS ON',
    stores: 'Stores',
    storeview: 'Store View',
    filter: 'Filter',
    totalshelfrevenue: 'Total Shelf Revenue',
    layout: 'Layout',
    profitpersqrmeters: 'Profit Per Square meter',
    sale: 'Sale',
    saleper: 'S%',
    salepercentage: 'SALE%',
    saleface: 'S/FACE',
    saleface_day: 'S/FACE DAY',
    profitper: 'P%',
    psqft: 'P.SqFt',
    accessdenied: 'Access Denied',
    dashboard: 'Dashboard',
    newdashboard: 'New Dashboard',
    masterdata: 'Master Data',
    storemanager: 'Store Manager',
    inbox: 'Inbox',
    logout: 'Logout',
    darkmode: 'Dark Mode',
    profilesettings: 'Profile Settings',
    showing: 'Showing',
    to: 'to',
    results: 'results',
    of: 'of',
    DATA_LOADING_PLEASE_WAIT: 'Data loading please wait...',
    MORE_MESSAGES: 'More Messages',
    GO_BACK: 'Go Back',
    SUCCESSFULLY_DEPARTMENT: 'Successfully department ',
    ERROR_OCCURRED: 'Error occurred',
    ERROR_OCCURRED_IN: 'Error occurred in ',
    SUCCESSFULLY_DEPARTMENT_DELETED: 'Department successfully deleted',
    ALREADY_ADDED: 'Already added',
    FILL_MAIN_DETAILS_CORRECTLY_TO_CONTINUE_EX_WHU:
        'Fill main details correctly to continue. ex- width, height, uom...',
    DISPLAY_UNIT_NAME_REQUIRED: 'Display unit name required',
    DISPLAY_UNIT_WIDTH_REQUIRED: 'Display unit width required',
    DISPLAY_UNIT_HEIGHT_REQUIRED: 'Display unit height required',
    DISPLAY_UNIT_DEPTH_REQUIRED: 'Display unit depth required',
    SHELVE_HEIGHT_REQUIRED: 'Shelve height required',
    SHELVE_GAP_REQUIRED: 'Valid shelve gap required',
    DISPLAY_UNIT_SHELVECOUNT_REQUIRED: 'Display unit shelve count required',
    DISPLAY_UNIT_UNIT_OF_MESURE_REQUIRED:
        'Display unit unit of measure required',
    DISPLAY_UNIT_SHELVES: 'Display unit shelves',
    HEIGHT_OF_SHELVES_DOESNT_MATCH_DISPLAY_UNIT_HEIGHT:
        "Heights of shelves doesn't match display unit height.",
    DISPLAY_UNIT_SUCCESSFULLY: 'Display unit successfully',
    PREVIEW_LOADING: 'Preview Loading...',
    EXCEL_DATA_UPLOADED_SUCCESSFULLY: 'Excel data uploaded successfully',
    ADD_EXCEL_FILE_TO_CONTINUE: 'Add excel files to continue',
    ADD_FLOOR_TO_CONTINUE: 'Add floor to continue',
    FLOOR_NAME_REQUIRED: 'Floor name required',
    FLOOR_WIDTH_REQUIRED: 'Floor width required',
    FLOOR_HEIGHT_REQUIRED: 'Floor height required',
    UNIT_OF_MESSURE_REQUIRED: 'Unit of measure required',
    SUCCESSFULLY_FLOOR_DETAILS: 'Successfully floor details ',
    FLOOR_WIDTH: 'Floor Width',
    FLOOR_HEIGHT: 'Floor Height',
    SELECT: 'Select',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARCH: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOMBER: 'Octomber',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    JAN: 'Jan',
    FEB: 'Feb',
    MAR: 'Mar',
    APR: 'Apr',
    JUN: 'Jun',
    JUL: 'Jul',
    AUG: 'Aug',
    SEP: 'Sep',
    OCT: 'Oct',
    NOV: 'Nov',
    DEC: 'Dec',
    CONFIRM_DELETE_PLANOGRAM: 'Confirm to delete planogram',
    ARE_YOU_SURE_DELETE_THIS_PLANOGRAM:
        'Are you sure to remove this planogram?',
    VERSION_NO_DIDNT_MATCH: 'Version number did not match',
    PLANOGRAM_SUCCESSFULLY: 'Planogram successfully ',
    CONFIRM_TO_DELETE_FIELD: 'Confirm to delete field',
    ARE_YOU_SURE_REMOVE_THIS_FIELD_FROM_FLOOR:
        'Are you sure to remove this field from this floor?',
    NO_TAGS_FOUND: 'No tags found!',
    SUCCESSFULLY_QR_DETAILS_EMAILD: 'Successfully QR details emailed',
    ENTER_VALID_EMAIL: 'Enter a valid email',
    CHANGE_PLANOGRAM_STATUS: 'Change planogram status',
    DO_YOU_WANT_TO_CHANGE_LAYOUT_STATE_TO:
        'Do you wants to change layout state to ',
    SHARE: 'Share',
    SHARE_QR: 'Share QR',
    EMAIL: 'Email',
    ALL: 'All',
    BEST: 'Best',
    WORST: 'Worst',
    BESTNWORST: 'Best & Worst',
    WIDTH_HEIGHT_OF_FIELD_CANNOT_LOWER_ZERO:
        'Width/height of field cannot be lower than zero.',
    HEIGHT_GAPS_OF_SHELVES_DOESNT_MATCH_DUINT_HEIGHT:
        "Heights/gaps of shelves doesn't match display unit height.",
    HEIGHT_GAP_NOT_ADDED_ROWS_FOUND_ADD_H_G_REMOVE_UNWANTED_ROWS:
        'Height and gap not added rows found, add height/gap or remove unwanted rows',
    CLOSE_FIELD_DETAILS_EDIT_VIEW_FIRST: 'Close field details edit view first',
    NO_RESULT_FOUND: 'No results found',
    PRODUCT_NOT_SELECTED: 'Product is not selected !',
    PROPERLY_EDIT_AND_CLOSE_FIELD_DETAILS_EDIT_VIEW_TO_CONTINUE:
        'Properly edit and close field details edit view to continue',
    CLOSE_PRODUCT_VIEW_TO_CONTINUE: 'Close product view to continue',
    RESET_NEW_CHANGES: 'Reset new changes',
    ARE_YOU_SURE_TO_RESET_ALL_NEW_CHANGES:
        'Are you sure you want to reset all new changes? ',
    RESET: 'RESET',
    RESETTED: 'Resetted',
    ARE_YOU_SURE_YOU_WANT_TO_RESET: 'Are you sure you want to reset? ',
    DISCONNECT: 'DISCONNECT ',
    ARE_YOU_SURE_YOU_WANT_TO_DISCONNECT:
        'Are you sure you want to disconnect? ',
    YOU_HAVE_UNSAVED_CHANGES_SURE_TO_LEAVE:
        'You have unsaved changes, are you sure you want to leave?',
    WIDTH: 'Width: ',
    HEIGHT: 'Height: ',
    DEPTH: 'Depth: ',
    PRODUCT_ALREADY_ADDED_REMOVE_ADDED_PRODUCTS_TO_CHANGE:
        'Product already added. remove added products to change',
    UPDATE_PRODUCT_DETAILS: 'Update product details',
    ARE_YOU_SURE_TO_UPDTAE_PRODUCT_DETAILS:
        'Are you sure to update this product details?',
    PRODUCT_DETAILS_SUCCESSFULLY_UPDATED:
        'Product details successfully updated',
    THIS_NOT_EFFECT_ELIER_PLANO_ONLY_FOR_THIS_AND_FUTURE_PLANO:
        'This change will not effect earlier planograms. Only applying for this and future planograms.',
    VIEW_USED_PLANOGRAMS: 'VIEW USED PLANOGRAMS',
    ENTER_VALID_JSON_O_TURN_ON_CONVERT_JSON_OPTION:
        'Enter valid JSON or turn on convert JSON option',
    DRAW_ERRORS: 'DRAW ERRORS',
    SHELVE: 'Shelve',
    BARCODE: 'barcode: ',
    ADD_A_PRODUCT_NAME: 'Add a product name',
    ADD_PRODUCT_W_H_B_DETAILS: 'Add product width,height and depth details',
    ADD_PRODUCT_SENSITIVITY: 'Add product sensitivity',
    ADD_PRODUCT_BARCODE: 'Add product barcode',
    SELECT_A_PRODUCT_SOURCE: 'Select a product source',
    ADD_IMAGE_OF_PRODUCT: 'Add an image of product',
    PRODUCT_DETAILS_SUCCESSFULLY: 'Product details successfully ',
    SUCCESSFULLY_PRODUCT_DETAILS_DELETED:
        'Successfully product details deleted',
    SUCCESSFULLY_PRODUCT_DETAILS_SAVED: 'Successfully product details saved',
    EMAIL_CONFIRMATION_DETAILS_SENT: 'Email confirmation details sent',
    CHECK_YOUR_EMAIL_FOR_CONFIRMATION_MESSAGE:
        'Check your email for confirmation message',
    FORGOT_ACCOUNT_PW: 'Forgot account password',
    ENTER_EMAIL_USED_TO_REGISTER_WITH_PLANIGO:
        'Enter email address used to register with Planigo.',
    RESET_ACCOUNT: 'Reset Account',
    STORE_NAME: 'Store Name',
    STORE_DETAILS: 'Store Details',
    SUCCESSFULLY_TAG_DETAILS: 'Successfully tag details ',
    SUCCESSFULLY_TAG_DETAILS_DELETED: 'Successfully tag details deleted',
    ERROR_TAG_DETAILS_DELETED: 'Cannot Delete! Tag Name is Empty',
    DRAG_N_DROP_SOME_FILES_HERE_OR_CLICK:
        "Drag 'n' drop some files here, or click to select files",
    IMAGE_IS_LARGER_THAN_5MB: 'Image is larger than 5mb',
    CONFIRM_TO_SUBMIT: 'Confirm to submit?',
    ARE_YOU_SURE_TO_CONTINUE_THIS_TASK: 'Are you sure to continue this task?',
    FILTER_BY: 'Filter by',
    COMPANY_NAME: 'Company Name',
    CONNOT_FIND_REQUESTED_CALL: 'Cannot find requested call',
    REQUIRED_AN_EMAIL: 'Required an email',
    REQUIRED_TO_FILL: 'Required to fill',
    REQUIRED_NUMBERS_ONLY: 'Required numbers only',
    CHOOSE: 'Choose...',
    DIRECT: 'Direct',
    ADDED: 'Added',
    REMOVED: 'Removed',
    RESET_EDIT_FIELD_DETAILS: 'Reset edit field details',
    DO_YOU_WANTS_TO_RESET_ADDED_CHANGES: 'Do you wants to reset added changes?',
    NOT_AVAILABLE_ANY_PROPOSAL_ITEMS: 'Not available any proposal items',
    COPIED_TO_CLIP_BOARD: 'Copied to clipboard',
    PLEASE_WAIT: 'PLEASE WAIT',
    SHELVEM: 'Shelve:',
    COL: ', COL:',
    NOT_FOUND: ' not found.',
    BARCODEM: ', barcode:',
    LOCATIONM: ', location:',
    BLOCKM: ', block:',
    CANNOT_ADD_OVERFLOW_Y: ' cannot add. (overflow y)',
    CANNOT_ADD_OVERFLOW_X: ' cannot add. (overflow x)',
    CANNOT_ADD_PVERFLOW_PRODUCT: 'cannot add. (overflow product)',
    IMAGE_LOADING: 'IMAGE LOADING...',
    IMAGE_CANNOT_LOAD: 'IMAGE CANNOT LOAD',
    shelveD: 'SHELVE-',
    LOADING: 'LOADING',
    language: 'Language',
    notifications: 'Notifications',
    refreshnotifications: 'Refresh Notifications',
    morenotifications: 'More Notifications',
    branch: 'Branch',
    editbranch: 'Edit Branch',
    addbranch: 'Add New Branch',
    proposals: 'Proposals',
    branchcode: 'Branch Code',
    branchname: 'Branch Name',
    branchaddress: 'Branch Address',
    suggestprodviewlevel: 'Add suggest product view level',
    salesimportbcode: 'Add sales import branch code',
    addremoveqty: 'Add remove quantity',
    selectbranchmanager: 'Select branch manager',
    regiondetails: 'Region details',
    editregion: 'EDIT REGION',
    addnewregion: 'ADD NEW REGION',
    country: 'Country',
    name: 'Name',
    code: 'Code',
    city: 'City',
    regionmanager: 'Regional Manager',
    location: 'Location',
    address: 'Address',
    suggestionprodlevel: 'Suggest Product View Level',
    salesimportbranchcode: 'Sales Import Branch Code',
    telephone: 'Telephone',
    removeqty: 'Remove Quantity',
    branchmanager: 'Branch Manager',
    branches: 'Branches',
    regions: 'Regions',
    region: 'Region',
    general: 'General',
    hierarchy: 'Hierarchy',
    selectcountry: 'Select Country',
    addcode: 'Add Code',
    addname: 'Add Name',
    selectarea: 'Select Area',
    fieldisrequired: 'This field is required',
    entervalidaname: 'Enter valid name',
    entervalidacode: 'Enter valid code',
    entervalidaddress: 'Enter valid address',
    entervalidlocation: 'Enter valid location',
    entervalidcity: 'Enter valid city',
    entervalidtelephone: 'Enter valid telephone',
    entervalidbranchcode: 'Enter valid branch code',
    entervalidprodlevel: 'Enter valid product level',
    entervalidquantity: 'Enter valid quantity',
    entervalidemail: 'Enter valid email',
    select: 'Select',
    USERS: 'Users',
    FILTER_USERS: 'Filter Users',
    ADDED_USERS: 'Added Users',
    SEARCH_USER: 'Search User',
    USER_NAME: 'User Name',
    ROLE: 'Role',
    ROLES: 'Roles',
    BRANCH: 'Branch',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    allregions: 'All Regions',
    allbranches: 'All Branches',
    firstnamerequired: 'First name required',
    lastNamerequired: 'Last name required',
    phonerequired: 'Telephone number required',
    emailrequired: 'Email address required',
    addressrequired: 'Address required',
    rolerequ: 'User role required',
    regionIdrequ: 'User region required',
    branchreqrequ: 'Branch required',
    resetpassword: 'Reset Password',
    passrequired: 'User password required',
    personaldet: 'Account Settings',
    changepw: 'Change Password',
    newpw: 'New Password',
    confirmpw: 'Confirm Password',
    oldpw: 'Current Password',
    userheirachy: 'Organization Tree',
    roletype: 'Role',
    COPY: 'Copy',
    currentdimentions: 'Current Dimentions',
    selectrotation: 'Select Rotation',
    rotation: 'ROTATION',
    front: 'Front',
    front_rotate: 'Front Rotate',
    side: 'Side',
    side_rotate: 'Side Rotate',
    top: 'Top',
    top_rotate: 'Top Rotate',
    default: 'Default',
    overlap_safty: 'Overlap Safety Margin',
    // task Files
    TASK_FEED: 'Task Feed',
    CONFIRM_DELETE_TASK: 'Confirm Delete Task',
    ARE_YOU_SURE_DELETE_THIS_TASK:
        'Are you sure you want to delete this task ?',
    TASK_IS_DELETED: 'Task is deleted successfully !',
    SAVE_TASK: 'Save Task',
    SELECT_LOCATION: 'Select Location',
    MORE_FILTERS: 'More Filters',
    TASK_NAME: 'Task Name',
    ASSIGED_TO: 'Assigned to',
    PROGRESS: 'Progress',
    STARTDATE_AND_TIME: 'Start Date and Time',
    ENDDATE_AND_TIME: 'End Date and Time',
    FREE_SEARCH: 'Free Search',
    ADD_NEW_TASK: 'Add New Task',
    UPDATE_TASK: 'Update Task',
    CANCEL: 'Cancel',
    NEW_TASK: 'New Task',
    TASK_DETAILS: 'Task Details',
    DESCRIPTION: 'Description',
    CATEGORY_OPTIONAL: 'Category (optional)',
    SELECT_CATEGORY: 'Select Category',
    DESCRIBE_THE_TASK_SHORTLY: 'Describe the task shortly',
    ANY_ADDITIONAL_INFO_ABOUT_TASK: 'Any additional information about the task',
    WHO_IS_THE_TASK_IS_FOR: 'Who the task is for?',
    TASK_APPROVER: 'Task Approver',
    TASK_SHEDULING: 'Task Scheduling',
    WHEN_THE_TASK_OCCUR: 'When the task occur',
    THIS_TASK_HAVE_TIMEFRAME: 'This task have a time frame',
    ONCE_DATE: 'Once Date :',
    YEAR_DATE: 'Year Date :',
    UNTIL_DATE: ' Until Date :',
    START_TIME: ' Start Time:',
    END_TIME: ' End Time:',
    FEED_BACK: 'Feedback',
    SELECT_FEEDBACK_MEDIA_OPTIONAL: 'Select feedback media(optional)',
    SELECT_FEEDBACK_TYPE: 'Select feedback type',
    SELECT_FROM_A_LIST: 'Select from a list',
    CHECK_LIST: 'Checklist',
    COMMENT: 'Comment',
    NUMBER: 'Number',
    URGENT_TASK: 'This task is urgent',
    START_FILLING_OUT_THE_CHECKLIST: 'Start filling out the checklist',
    START_FILLING_OUT_THE_BRANCH: 'Start filling out the branch',
    START_FILLING_PUT_THE_LIST: 'Start filling out the list',
    ADD_ANOTHER_ITEM: 'Add another item',
    WHO_WILL_APPROVE_THE_TASK: 'Who will approve the task?',
    SELECT_ALL_REGIONS: 'Select all regions',
    SELECT_ALL_STORES: 'Select all stores',
    SELECT_ALL_WORKERS: 'Select all workers',
    SELECT_NONE: 'Select none',
    WORKERS: 'Workers',
    WHO_WILL_ALLOCATE_THE_TASK: 'Who will allocate the task?',
    ASSIGN: 'Assign',
    SELECT_ASSIGNEE_FOR: 'Select assignee for',
    SEARCH_EMPLOYEE: 'Search employee',
    APPLY: 'Apply',
    MY_TASKS: 'My Tasks',
    TEAM_TASKS: 'Team Tasks',
    //task summery
    TOTAL_COUNT: 'Total count:',
    IN_AREA: 'In area :',
    DONE: 'Done',
    Issue: 'Issue',
    IN_PROGRESS: 'In Progress',
    ALL_REGIONS: 'All Regions',
    NO_CHAT_RESULTS: 'No Chat Results',
    COUNTED: 'Counted',
    SELECTED_CHOICE: 'Selected Choice',
    ANSWER: 'Answer :',
    OPEN_CHAT: 'Open chat',
    CLOSE_CHAT: 'Close chat',
    ASKTO_REDO: 'Ask to redo',
    SENDREDO_REQ: 'Send redo request',
    ADD_RES_TO_REDO: 'Add Reason for redo',
    //new llll
    FILTER: 'Filter',
    URGENT: 'Urgent',
    ASSIGNEE: 'Assignee',
    PROGRESS_IN_PERCENT: 'Progress in percent',
    DATE_ANMND_TIME_RANGE: 'Date and time range',
    APPROVE: 'Approve',
    EDIT: 'Edit',
    REDO_REQUIRED: 'Redo Requierd',
    PLEASE_SELECT_AT_LEAST_ON_ASSIGNEE: 'Please select at least one assignee',
    TASK_ASSIGN_DETAILS_SAVED: 'Task assign details saved',
    APPLY_FILTER: 'Apply Filters',
    RESET_FILTERS_SHOWALL: 'Reset Filters',
    FROM: 'From',
    TO: 'To',
    PLEASE_SE_DATE_RANGE: 'Please set date range !',
    PLEASE_SET_A_FROM_DATE: 'Please set a from date !',
    PLEASE_SET_TO_DATE: 'Please set a to date !',
    COMMENT_ADDED: 'Comment added !',
    PLEASE_ADD_COMMENT: 'Please add a comment !',
    EXPLAIN_WHY_REDO_NEED: 'Explain why redo is needed !',
    REDO_ADDED: 'Redo added !',
    REDONE: 'Redone',
    RESON_FOR_REDO: 'Reason for redo:',
    NO_IMG_PLACED_YET: 'No image placed yet',
    ADD_COMMENT: 'Add comments',
    MUST_USE_CAM: 'Must use camera',
    CONFIRM_OVELAP_ERROR:
        'Some planogram fields overlaping with active planogram!',
    REARRANGE: 'Rearrange',
    TASK_CAN_BE_APPROVE: 'Task can be approved',
    TASK_CANNOT_BE_APPROVED: 'Task cannot be approved',
    SUBTASK_COMPLETED_SURE_TO_APPROVE_THIS_TASK:
        'All sub tasks are completed are you sure you want to approve this task ?',
    SEE_TASK_DETAILS: 'See Task Details',
    CANNOT_APPROVE_UNCOMPLETED_SUB_TASKS:
        'This task cannot be approved because there are un-completed sub-tasks',
    SOME_OF_USERS_HVNT_COMPTASKS_WANT_TO_APPROVE:
        "Some of the users haven't accomplish to complete the task. Are you sure you want to approve this task ?",
    APPROVE_ANYWAY: 'Approve Anyway',
    ALLOW_OVERLAP: 'Allow Overlap',
    DISABLE_OVERLAP: 'Disable Overlap',
    MORE_VERSIONS: 'More Versions',
    OPEN: 'Open',
    left_field: 'Left Field',
    right_field: 'Right Field',
    no_dept: 'No Department',
    only_field: 'ONLY FIELD',
    CANNODELETE_HAS_OVELAP_PRODUCT:
        'This field cannot delete, Because it has overlap product !',
    CANNOTDRAG_HAS_OVERLAP_PRODUCT:
        'Cannot drag field, Because it has overlap product!',
    HAVE_UNSAVE_CHANGES_UPPDATE_BEFORE_CONFIRM:
        'You have unsaved changes, Please update before confirm!',
    UNSAVE_CHANGES: 'Unsaved Changes',
    OVELAP: 'Overlapping!',
    CONFIRM_TO_CHANGE_USER_REGION: 'Confirm to change user region',
    THIS_HAVE_REGION_MANAGER_SURE_TO_SET:
        'This region already have a region manager, Are you sure to set this user as region manager?',
    CONFIRM_TO_CHANGE_USER_BRANCH: 'Confirm to change user branch',
    CONFIRM_TO_CHANGE_USER_BRANCH_MESSAGE:
        'This branch already have a branch manager, Are you sure to set this user as branch manager?',
    CONFIRM_TO_DELETE_USER: 'Confirm to delete user',
    CONFIRM_TO_DELETE_USER_MESSGAE: 'Are you sure to remove this user?',
    SUCCESSFULL_UPDATE_PASSWORD: 'Successfully password updated',
    CONFIRM_RESET_USERPASSWORD: 'Confirm to reset user password',
    CONFIRM_RESET_USERPASSWORD_MESSAGE:
        'Are you sure to reset this user password?',
    FEED: 'Feed',
    OLDPASSWORD_REQ: 'Old password required',
    NEWPW_REQ_MIN4LENGTH: 'New password required. (min. length 4)',
    CONFIRM_PW_REQ: 'Confirm password required',
    NEWPW_CANNOT_SAME_OLD: 'New password cannot be same as old password',
    CONFIRM_PW_DOESNT_MATCH_NEWPW:
        "Confirm password doesn't match new password",
    HAVE_UNSAVE_CHANGES_UPPDATE_BEFORE_THIS_TASK:
        'You have unsaved changes, Please update before do this task!',
    WHAT_YOU_WANT_EXPLORE: 'What do you want to explore today?',
    SALE_PER_FACE: 'Sale Per Face',
    GENERAL_SALES: 'General Sales',
    SEL_TIME_RANGE: 'Select Time Range',
    SELTED_TIME_RANGE: 'Selected Time Range',
    SHOW: 'Show',
    TIME_RANGE: 'Time Range',
    VERSIONS: 'Versions',
    SELDEPT_AVAILABLE:
        "Selecting department doesn't matching current selected product department",
    persalesfromrevenue: '% sales from AVG (revenue)',
    changeFromAvg: 'Change from AVG',
    perfromsimilarstore: '% from similar AVG store revenue',
    changefromavgspf: 'Change from AVG SPF',
    perofavgfacingqty: '% of AVG FACING qty',
    Filter: 'Filter',
    NEW: 'New',
    RESETALL: 'Reset All',
    NEWGOAL: 'New Filter',
    ADDNEWGOAL: 'Add New Filter',
    EDITNEWGOAL: 'Edit New Filter',
    SAVEGOAL: 'Save Filter',
    UPDATEGOAL: 'Update Filter',
    goalmeasurename: 'Filter Name',
    searchmeasure: 'Measure',
    condition: 'Condition',
    optional: 'Optional',
    equal: 'Equal',
    notequal: 'Not Equal',
    greaterthan: '> Greater Than',
    lesserthan: '< Lesser Than',
    goalsuccesssaved: 'Filter details successfully saved',
    goalsuccessdeleted: 'Filter details successfully deleted',
    showresults: 'Show results',
    resultscount: 'Results count',
    afilterselected: 'A filter selected',
    NOCHARTDATAAVAILABLE: 'No Chart Data Available',
    HEADQUATERS: 'Headquarters',
    NOSTORE: 'No Store',
    notavailable: 'N/A',
    chartview: 'Chart',
    tableview: 'Table',
    FROM_DATETIME: 'From Date Time',
    TO_DATETIME: 'To Date Time',
    TASK_NAME_REQ: 'Task name required !',
    DECRIPTION_REQ: 'Description required !',
    SELECT_WHO_THE_TASK_IS_FOR: 'Select who the task is for !',
    SELECT_WHO_WILL_APPROVE_THE_TASK: 'Select who will approve the task !',
    SELECT_REPEAT_TYPE: 'Select repeat type !',
    ONCE_DETAILS_REQ: 'Once details required !',
    START_TIME_REQ: 'Start time required !',
    WEEKLYDETAILS_REQ: 'Weekly details required !',
    UNTIL_DATE_REQ: 'Until Date required !',
    YEARLY_DATE_REQ: 'Yearly date required !',
    END_TIME_REQ: 'End time required !',
    UNTILDATE_REQ: 'Until date required !',
    WEEK_REQ: 'Week required !',
    DAY_REQ: 'Day required !',
    PLEASE_FILL_FEEDBACK: 'Please fill feedback!',
    PLEASE_FILL_ALL_FIELDS_FEEDBACK: 'Please fill all fields feedback !',
    PLEASE_FILL_FEEDBACK_TYPE_OPTIONS: 'Please fill feedback type options!',
    IN_WICH_WEEK_OFTHE_MONTH: 'In which week of the month?',
    IN_WICH_DAY_OF_THE_WEEK: 'In which day of the week ?',
    ONCE: 'Once',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    FIRST: 'First',
    SECOND: 'Second',
    THIRD: 'Third',
    FOURTH: 'Fourth',
    SUN: 'Sun',
    MON: 'Mon',
    TUE: 'Tue',
    WED: 'Wed',
    THU: 'Thu',
    FRI: 'Fri',
    SAT: 'Sat',
    CHANGE_CURRENT_STORE: 'Change Current Store',
    THISWILL_REDIRECT_LANDING_PAGE_R_U_SURE:
        'This will redirect to landing page, Are you sure?',
    LATE: 'Late',
    FUTURE: 'Future',
    TODO: 'Todo',
    For_my_Approval: 'For my approval',
    COMPLETE: 'Complete',
    NOW: 'Now',
    FILL_ALL_REQ_FIELDS: 'Fill all required fields!',
    VIDEO: 'Video',
    PICTURE: 'Picture',
    PLEASE_ADD_AT_LEASTONE_FIELD_TOSAVE: 'Please add fields to save',
    PLANOGRAMIS_LOCKED: 'Planogram is locked!',
    REMOVELOCK_PLANO: 'Remove planogram lock',
    SURE_REMOVE_PLANO: 'Are you sure you want to remove planogram lock?',
    NOTDONE: 'Not Done',
    I_CANNOT_DO: "Can't Do",
    ADD_VALID_APPROVERS: 'Please add valid approvers!',
    TYPE_TO_SEARCH: 'Type to search',
    CREATED_DATE: 'Created Date',
    ERROR_OCCORED_IN_PROCESS: 'Error occurred in process',
    EMP_HEIRACHY_SAVED: 'Employee hierarchy successfully saved',
    ADD_EMP_ROLES_CORRECTLY: 'Add employee role types correctly',
    ADD_EPM_NAMES_CORRECT: 'Add employee names correctly',
    USER_LEVEL: 'User Level',
    PLEASE_SET_CREATED_DATE_RANGE: 'Created date Shoud be a date range!',
    usergroups: 'User Groups',
    usercounts: 'User Counts',
    editusergroup: 'Edit User Group',
    addnewusergroup: 'Add New User Group',
    groupname: 'Group Name',
    addedgroups: 'Added User Groups',
    confirmdeletegroup: 'Confirm to delete a user group',
    confirmdeletegroupsub: 'Are you sure to delete this user group?',
    typegroupname: 'Type group name',
    selectusers: 'Select users',
    selarole: 'Select a role',
    selaregion: 'Select a region',
    selastore: 'Select a store',
    selagroup: 'Select a group',
    DATA_LOADING_FAIL: 'Data loading failed !',
    STORE: 'Store',
    GROUP: 'Group',
    SEARCH: 'Search',
    questionnaire: 'Questionnaire',
    addnewquestionear: 'Add New Questionnaire',
    addnewquestion: 'Add New Question',
    activitylog: 'Activity Log',
    addtonewtask: 'Add To New Task',
    save_question: 'Save Question',
    dismiss_changes: 'Dismiss Changes',
    NOSELECTEDQUEST: 'NO SELECTED QUESTIONNAIRE',
    USEDINTASKS: 'Used in Tasks',
    CREATEDBY: 'Created by',
    NUMBEROFQUESTIONS: 'Number of questions',
    QUESTNAME: 'Questionnaire Name',
    NOSELECTEDQUESTION: 'NO SELECTED QUESTION',
    QUESTION: 'Question',
    STAGEQUESTION: 'Stage Question',
    FEEDBACK_TYPE: 'Feedback type',
    REQUIRED_MEDIA: 'Required media',
    ACTIONON_COMPLETE: 'Action on complete',
    AFTER_COMPLETE_ACTION: 'After complete action',
    TYPE_ANSWER: 'Type answer',
    TYPE_ACTIONHERE: 'Type action here',
    SELECT_ACTION: 'Select action',
    SELECT_QUESTION_TO_GOTO: 'Or select a question to go to',
    TARGET_QUESTION: 'Target Question',
    ALREADYLOADING: 'Already loading, Please wait...',
    EDIT_TASK: 'Edit Task',
    TASK_USE_QUESTIONIER: 'This task use questionnaire',
    SELECTQ_OR_CREATE_NEW: 'Select questionnaire or create new one',
    NEWQUESTIONER_CAN_CREATE_IN: 'New questionnaire can be created in ',
    QUESTIONNAIRE: 'Questionnaires',
    TYPEQUESTNAIRENAME: 'Type questionnaire name',
    TYPEQUESTIONNAME: 'Type question name',
    NEXT: 'Next',
    NONE: 'None',
    GOTO: 'Go To',
    published: 'Published',
    draft: 'Draft',
    replaced: 'Replaced',
    deleteaquestionear: 'Delete a questionnaire',
    areyousuretodeletethisquestionear:
        'Are you sure to delete this questionnaire?',
    succesfullyquestioneardeleted: 'Successfuly questionnaire deleted',
    questionnamereq: 'Question name required',
    questcheckoption: 'Question check option',
    questselectitem: 'Question selection item',
    questbranchitem: 'Question branch item',
    gotoquestion: 'go to question required',
    namerequired: 'Name required',
    questinearnamereq: 'Questionnaire name required',
    addatleastonequest: 'Add at least one question',
    successquestsaved: 'Successfuly questionnaire saved',
    somequestcompletenotadded: 'Some questions complete action not added',
    doneavailmorethanone: 'Done action available more than one',
    somequestlistnotavail: 'Some questions list items not added',
    somebranchitemsactype:
        'Some questions branch items have not setted action types',
    lastquestnotavaildone: "Last question doen't available done action",
    successquestpublished: 'Successfuly questionnaire Published',
    successquestionsaved: 'Successfuly question Saved',
    saveorclosenewquest: 'Save or close new question first',
    savenewquestarrange: 'Save new question list arrangements first',
    saveorclosequestion: 'Save or close opened question first',
    otherquestionsavail: 'Other question links available from this question,',
    deleteaquestion: 'Delete a question',
    areyousuretodeletequestion: 'Are you sure to delete this question?',
    publishaquestinear: 'Publish a questionnaire',
    areyousuretopublish: 'Are you sure to publish this questionnaire?',
    somequestionextgotolinks: 'Some questions next/go to links are wrong',
    questlinkswillbereplaced:
        'Question branch go to links will be replaced to None',
    changingquestview: 'Changing question view',
    areyousuretochangequestion:
        'Question changes available, are you sure to change question?',
    skippedquestavlble: 'Skipped questions available, Link them before publish',
    unpublishaquestinear: 'Unpublish a questionnaire',
    areyousuretounpublish: 'Are you sure to unpublish this questionnaire?',
    successquestunpublished: 'Successfuly questionnaire unpublish',
    APPLY_FILTERS: 'Apply Filters',
    HEADQUARTERS: 'Headquarters',
    QR_SUCCESS: 'Scanned',
    QR_FAIL: 'Pending',
    MARK_AS_CANNOTDO_SUCCESS: 'Successfully marked as can not do!',
    SELECT_QUESTION:
        'This questionnaire is already being attended! Please select a question!',
    CANNOT_Do: 'Can Not Do',
    CONFIRM_ALL_QUESTIONS: "Confirm can't do entire questionnaire",
    CONFIRM_ALL_QUESTIONS_MESSAGE:
        'This questionnaire is not yet completed. Are you sure to mark the entire questionnaire as cannot do?',
    SURETO_CONTINUE: 'Are you sure to continue?',
    CONFIRM_BRANCH_THE_ALL_CANNOTDO:
        'Selected questions includs branch type questions. So that entire pending questions will be mark as " Can Not Do"',
    PENDING: 'Pending',
    SEEMORE: 'See More +',
    NORESULTFOUNDSEARCH: 'No results found for search',

    connection_offline: 'Connection Offline',
    slow_connection: 'Slow Connection',
    MARKAS_CANNOT_DO:
        'This task will mark as cannot do. Do you want to continue?',
    STORE_TASK_PROGRESS: 'Store Task Progress',

    uomlist: {
        meters: 'Meters',
        cm: 'Centimeters',
        inches: 'Inches',
        feet: 'Feet',
        CM_SHORT:'cm'
    },
    SET_ISLE: 'Set Isle',
    ISLECHANGES_NOT_SET: 'Set isle to continue',
    CHANGES_OF_ISLE_NOTSET_PLEASE_SETISLE_BEFORE:
        'Please set isle before this process',

    FILE_UPLOADING: 'File Uploading...',

    SELECT_MEASURE: 'Select a measure',
    SELECT_CONDTION: 'Select a condition',
    SELECT_DEPARTMENT: 'Select a department',
    SELECT_STORETAG: 'Select a store tag',
    SELECT_PRODUCT: 'Select a product',
    ADD_ALL: 'Add All',
    WEBSITE_IS: 'Website is',
    UNDER_CONST: 'under maintenance',
    RELOAD_APP: 'Reload Application',

    ISRIGHTCHANGE: 'Is Right Change',
    ISLEFTCHANGE: 'Is Left Change',

    qty_add: 'Qty Add',
    add_new_block: 'Add New Block',
    qty_remove: 'Qty Remove',
    remove_block: 'Remove Block',
    remove_product: 'Remove Product',

    remove_dashboard_filter: 'Remove dashboard filter',
    areyousure_remove_filter: 'Are you sure to remove this filter?',
    redirect_from_dashboard: 'Redirect from dashboard',
    areyousure_redirect_tolayout:
        'Are you sure to redirect to planogram layout view?',
    storeactive_planogram_notfound: 'Store active planogram not found',
    filter_namerequired: 'Filter name required',
    filter_measurerequired: 'Filter measure type required',
    filter_conditionrequired: 'Filter condition required',
    filter_valuerequired: 'Filter number required',
    REMOVE_ALL: 'Remove All',
    SALES_LOG: 'Sales Log',
    SALES_DATE_N_TIME: 'Sales Date & Time',
    FILE_NAME: 'File Name',
    SYNC_DATE_N_TIME: 'Sync Date & Time',
    REMARK: 'Remark',
    SYNC_LOG: 'Sync Log',
    PRODUCT_LOG: 'Product Sync Log',
    PRODUCT_NAME: 'Product name',
    JOB_NUMBER: 'Job Number',
    SYNC_TYPE: 'Sync Type ',
    SALES_PLUS_TAX: 'Sales + Tax',
    QUANTITY: 'Quantity',
    SALES_DATE: 'Sale Date',
    BACK_TO_SYNCLOG: 'Back To Sync Log',
    PLEASE_SET_VALID_DATE_RANGE: 'Please set a valid date range!',
    PLEASE_SET_A_VALID_TIME: 'Please Set a valid Time!',
    PLEASE_SET_A_VALID_TIME_FOR_THE_DATE:
        'Please Set a Time for the same date!',
    PLEASE_SET_VALID_CREATED_DATE: 'Please Set a Valid Created Date!',
    PLEASE_SET_A_VALID_DATE: 'Please Set a Valid Date!',
    PLEASE_SET_A_UNTIL_DATE: 'Please Set an Until Date!',
    PLEASE_SET_A_VALID_DATE_FOR_TIME:
        'Please Set a valid date for time as it is already passing the starting time!',
    PLEASE_SET_TASK_OCCURANCE: 'Please Set Task Occurance!',
    PLEASE_SET_A_ONCE_DATE: 'Please Set a Once Date!',
    PRODUCTOBARCODE: 'Product, Barcode',
    SUCCESS: 'Success',
    FAIL: 'Fail',
    SALE_TO: 'Sales To Date',
    SALE_FROM: 'Sales From Date',
    SYNC_TO_DATE: 'Sync To Date',
    SYNC_FROM_DATE: 'Sync From Date',
    PLANIGO_TASK_ONLY: 'Planigo tasks only',
    DETAILED_VIEW: 'Detailed View',
    MANUAL_TRIGGER: 'Sync Now',
    SYNC_FAIL: 'Sync Fail!',
    SYNC_SUCCESS: 'Sync Success!',
    FORCE_ISSUE_SYNC: 'Force Issue Sync',

    manual_compliance: 'Manual Compliance',
    chain: 'Chain',
    jobno: 'Job No',
    jobdetails: 'Job Details',
    request_by: 'Requested By',
    requested_datetime: 'Requested Date & Time',
    active_view: 'Active View',
    review: 'Review',
    markthisascomp: 'Mark this as compiled',
    rectchanges: 'Changes',
    CONFIRM_TO_UPDATE: 'Confirm to update',
    PLEASE_TAKE_ACTION_BEFORE_UPDATE: 'Please take action before update!',
    MANUAL_COMPLANCE_SUBMITTED: 'Manual compliance submitted!',
    MANUAL_COMPLANCE_FAIL: 'Manual compliance submit fail!',
    PLEASE_ADD_REMARK: 'Please add remark!',
    SUCCESSFULLY_REDO_REQUESTED: 'Successfully redo requested!',
    REDO_REQUESTED_FAIL: 'Redo request fail!',
    ADD_REMARK_REDO: 'Add remark to redo',
    ADD: 'Add',
    REMOVE: 'Remove',
    DRAW_INSIDE_IMAGE: 'Please draw inside of image! ',
    CONFIRM_SYNC: 'Confirm Sync',
    ARE_YOU_RUN_iMMEDIATE_SYNC: 'Are you sure to run immediate Sync?',
    ARE_YOU_SURE_YOU_WANT_TO_FORCE_SYNC_SALE_DATE_FILES:
        'Are you sure you want to force sync the sale date files',
    THOSE_FILES_CAN_CONTAINE_DATA_WITH_MISSMATCH_WITH_LAST_SYNC_DATE:
        'Those files can contain data with date mismatches with last sync date?',
    ARE_YOU_SURE_THIS_CANNOT_BE_REVERSE: "Are you sure this can't be reverse?",
    THIS_PROCESS_CANNOT_BE_REVERSE_ARU_SURE:
        'This process cannot be reverse.are you sure to continue?',

    disable_pan: 'Disable pan tool first',
    chaindepartments: 'Department Categories',
    addNewDepartment: 'Add New Department',
    categories: 'Categories',
    categoryName: 'Category Name',
    addnewcategory: 'Add New Category',
    addnewsubcategory: 'Add New Sub Category',
    addnewbrand: 'Add New Brand',
    subCategory: 'Sub Category Name',
    enter_catgory_name_placeholder: 'Enter Category Name',
    enter_subcat_name: 'Enter sub category name',
    select_brand_placeholder: 'Select brand name',
    select_departement_warn: 'Select a department',
    category_name_empty_warn: 'Enter category name',
    add_categories_warn: 'Add categories',
    subcategory_name_empty_warn: 'Enter sub category name',
    brand_empty_warn: 'Select a brand',
    category_name_duplicate_warn: 'Category name is duplicated',
    subcategory_name_duplicate_warn: 'Sub category name is duplicated',
    brand_duplicate_warn: 'Brand is duplicated',
    addcategory: 'Add category',
    removecatgory: 'Remove category',
    addsubcategory: 'Add sub category',
    removesubcategory: 'Remove sub category',
    addbrand: 'Add brand',
    removebrand: 'Remove brand',
    searchdep: 'Search department',
    searchcategory: 'Search category',
    searchsubcat: 'Search sub category',
    searchbrand: 'Search brand',
    category: 'Category',
    subcategory: 'Sub category',
    selectcategory: '(Select category)',
    selectsubcategory: '(Select sub category)',
    already_added: 'Already added',
    SUCCESSFULLY_UPDATED: 'Successfully updated',
    SUCCESSFULLY_DELETED: 'Successfully deleted',
    SUCCESSFULLY_ADDED: 'Successfully added',
    SELECT_BRAND: 'Select brand',
    ENTER_SUB_CATEGORY_NAME: 'Enter sub category name',

    brands: 'Brands',
    search_brands: 'Search Brands',
    brandname: 'Brand Name',
    brandName: 'Brand Name',
    branddetails: 'Brand Details',
    BRAND_NAME_DUPLICATE: 'Duplicate brand name',
    suplable: 'Supplier',
    supselectplceholder: 'Select a supplier',
    SUCCESSFULLY_BRND_DETAILS_DELETED: 'Brand deleted successfully',
    SUCCESSFULLY_BRND_DETAILS_SAVED: 'Brand saved successfully',
    SUCCESSFULLY_BRND_DETAILS_UPDATED: 'Brand updated successfully',

    suppliers: 'Suppliers',
    search_suppliers: 'Search suppliers',
    supname: 'Supplier Name',
    supplierName: 'Supplier Name',
    supcode: 'Supplier Code',
    supplierCode: 'Supplier Code',
    supdetails: 'Supplier Details',
    SUPPLIER_CODE_DUPLICATE: 'Duplicate Supplier Code',
    ENTER_SUPPLIER_NAME: 'Enter Supplier Name',
    ENTER_SUPPLIER_CODE: 'Enter Supplier Code',
    SUPPLIER_NAME_DUPLICATE: 'Duplicate Supplier Name',
    SUCCESSFULLY_SUP_DETAILS_DELETED: 'Supplier deleted successfully',
    SUCCESSFULLY_SUP_DETAILS_SAVED: 'Supplier saved successfully',
    SUCCESSFULLY_SUP_DETAILS_UPDATED: 'Supplier updated successfully',

    CANT_DELETE_REFERENCE: 'You can not delete this when references available',
    DEP_WITH_CAT_EXISTS: 'Department With Category Already Exists.',
    ARE_YOU_SURE_TO_CONTINUE_DELETE_WILL_DELETE_DEPARTMENT:
        'This is the only catgery for the department. If you delete this, department will be deleted as well. Are you sure to continue?',
    NO_CATS_AVL_ARE_YOU_SURE_CONTINUE:
        'No categories available, Department will be removed if there are no categories. Are you sure to continue?',

    icon: 'Icon',
    selecticon: 'Select an icon',
    changeicon: 'Change Icon',
    productoptions: 'Product Options',
    ismvp: 'Is MVP',
    isontop: 'Is On-Top',
    isnoos: 'Is NOOS',
    isfav: 'Is Favorite',
    ispremium: 'Is Premium',
    minqty: 'Min.Qty',
    maxqty: 'Max.Qty',
    minrev: 'Min Revenue',
    maxrev: 'Max Revenue',
    shelvelife: 'Shelve Life',
    paceOfSalesInQty: 'Pace of Sale',
    Simulate: 'Simulate',
    CLOSE_SIMULATION: 'Are you sure to close simulation?',
    Select_tags: 'Select tags',
    PLEASE_SELECT_TAGS: 'Please select a tag!',
    PLASE_SADD_FIELD_COUNT: 'Please add valid field count!',
    YOU_HAVE_UNSAVED_CHANGES: 'You have unsaved changes',

    product_info: 'Product Information',
    product_options: 'Product Options',

    store_tags: 'Store Tags',
    planogram_tags: 'Planogram Tags',
    srchstoretag: 'Search Store Tag',
    srchpltag: 'Search Planogram Tag',
    selecttag: 'Select a Tag',
    addtag: 'Add Tag',
    select_tag: 'Select a Tag',
    tag_already_added: 'Already added',
    SURE_TO_REMOVE_TAG: 'Are you sure to remove this tag?',
    sub_categories: 'Sub Categories',
    PRODUCT_DETAILS: 'Product Details',
    isontopitem: 'On-Top Product',
    ismvpitem: 'MVP Product',
    on_top_products: 'On-Top Products',
    mvp_products: 'Most Valuable Products',
    ENTER_BRAND_NAME_WARN: 'Enter brand name',
    SELECT_BRAND_SUPPLIER_WARN: 'Select a supplier',
    manualOverride: 'MVP/On-Top \nOverride',
    ARE_YOU_SURE_TO_DELETE_Block: 'Are you sure to delete block?',
    THIS_WILL_DELETE_ALL_SELECTED_PRODS:
        'This will delete all selected products in block',
    paceofscale: 'Pace of Scale',
    NO_ADDED_PRODUCTS_MSG: 'No added products',
    NO_REMOVED_PRODUCTS_MSG: 'No removed products',
    CLIP_BOARD: 'Clip Board',
    CUT: 'Cut',
    ZOOM_IN: 'Zoom-in',
    ZOOM_OUT: 'Zoom-out',
    RESET_ZOOM: 'Reset zoom',
    PAN: 'Pan',

    master_planograms: 'Master Planograms',
    master_planogram: 'Master Planogram',
    newmasterplanogram: 'New Master Planogram',
    letsstart: "Let's Start",
    department_meta_data: 'Department Global Settings',
    continue_btn: 'Continue',
    skip_btn: 'Skip',
    no_added_categories: 'No categories added',
    required_minqty: 'minimum facing count cannot be empty or less than zero',
    required_maxqty:
        'maximum facing count cannot be empty or less than zero or less than minimum facing count',
    required_minrev: 'minimum revenue value cannot be empty or less than zero',
    required_maxrev:
        'maximum revenue value cannot be empty or less than zero or less than minimum revenue value',
    required_shelflife:
        'minimum shelve life value cannot be empty or less than zero',
    required_pacesale:
        'minimum pace of sale value cannot be empty or less than zero',
    all_categories: 'All Categories',
    default_category: 'Default Category',
    defaultsub_category: 'Default Sub Category',
    sub_category: 'Sub Category',
    dept_rules: 'Department Rules',
    cat_rules: 'Category Rules',
    subcat_rules: 'Sub Category Rules',
    REC: 'Rec',
    PROD_QTY: 'Prod. Qty',
    SELECT_SUPPLIER: 'Select Supplier',
    SELECT_RECT: 'Select Box',
    TOOLS: 'Tools',
    FIELD_COUNT: 'Field Count',
    SUMMARY: 'Summary',
    NO_SIMULATION_PREVIEW: 'No simulation to preview',
    ALL_CATEGORIES: 'All Categories',
    CLIPBOARD: 'Clipboard',
    removeproducts: 'Remove Products',
    save_masterplanogram: 'Save Master Planogram',

    selectfield: 'Select a field',
    catalreadyadded: 'Category already added',
    catsavedsuccess: 'Categories successfully saved',
    addcatsfirst: 'Add categories first',
    addcatfirst: 'Add category first',
    catnotfound: 'Category not found',
    selectasubcategory: 'Select sub category',
    activatedrawtool: 'Activate draw tool first',
    subcatsavesuccess: 'Sub categories successfully saved',
    drawboxesfirst: 'Draw boxes first',
    subcatnotfound: 'Sub category not found',
    addsubcatfirst: 'Add sub category first',
    brandssavedsuccess: 'Brands successfully saved',
    brandnotfound: 'Brand not found',
    addbrandfirst: 'Add brand first',
    notselectedsubcat: 'No selected sub category',
    notselectedbrand: 'No selected brand',
    oveflowinglayout: 'Overflowing layout area',
    overflowlayoutornoselected:
        'Overlapping layout area or no sub category available',

    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
    years: 'Years',

    per_day: 'Per Day',
    per_week: 'Per Week',
    per_month: 'Per Month',
    per_year: 'Per Year',

    EDIT_SIMULATE_CATEGORY: 'Edit Simulate Category',
    EDITALLCATEGORY: 'Simulate All Categories',
    PRODUCT_BLOCK_NOT_FOUND: 'Product block not found',
    CANNOT_ADD_TO_BOTTOM: 'Cannot add to bottom',
    DRAW_BLOCK: 'Draw Block',

    edit_categories: 'Edit Categories',
    edit_subcategories: 'Edit Sub Categories',
    edit_brands: 'Edit Brands',
    dep_rules: 'Dep Rules',
    SELECT_BRANCH: 'Select Branch',
    PUSH: 'Push',
    EDIT_PORTION: 'Edit Portion',

    DEPARTMENT_WARN: 'Department Warnings',
    WARN: 'Warnings',
    USED_DEPTS: 'Used Departments',
    WARN_PROD_BEEN_USED:
        'Warning!, This product has been used in other fields or departments',
    OKAY_NOTED: 'Okay, Noted',
    NEWPRODUCTS: 'New Products',
    EXTENDED_EDITING: 'Extended Editing',
    MASTER_PRODUCTS: 'Master Products',
    NEW_PRODUCTS: 'New Products',

    ARCHIVE: 'Archive',
    DATA: 'Data',
    SEND_TO_DEP: 'Send to Department',
    NO_DATA: 'No Data',

    MP_DEPSEL_TITLE: 'This is your master planogram',
    sale_start_date: 'Sale Start Date',
    sale_end_date: 'Sale End Date',

    open_subcategory: 'Open sub category view',
    enable_draw: 'Enable draw',
    open_brand: 'Open brand view',
    open_products: 'Open product view',
    cannot_resize_minimum_brands: 'Cannot resize minimum than added brands',
    MANUALY_ENTER_FIELD_COUNT: 'Manually enter field count',
    MANUALY_ENTER_FIELD_COUNT_description:
        'Selected branch has no fields to simulate. Please enter field count and simulate',
    OKAY: 'Okay',
    percentage: 'Percentage',
    fields: 'Fields',
    shelves: 'Shelves',
    dynamic_search: 'Dynamic search',
    data_from_cashiers: 'Data from cashiers',

    SUCCESSFULLY_PUSHED_MP_TO_BRANCH: 'Successfully pushed to branch',
    MP_PUSH_FAIL: 'Master planogram failed to push',
    CONFIRM_TO_PUSH: 'Are you sure to push this simulation?',
    THIS_SIMULATION_IS_GOING_TO_PUSH_TO_BRANCH:
        'This simulation is going to push ',

    DATES_OUT_OF_ODER: 'Dates Out of Order',
    DATES_ORDER: 'Date Order',
    IN_ORDER: 'In Order',
    NO_ISSUE: 'No Issue',
    CURRENT_BRANCH_LAST_SYNC: 'Current Branch - Last Sync',
    ALL_BRANCH_LASET_SYNC: 'All Branches - Last Sync',
    ISSUES: 'Issues',
    SALEDATA_MISSINGIN: 'Missing',
    REVERSE_SALE_FILE: 'Reverse sale file',
    SURE_REVERSE_THIS_SALE: 'Are you sure to reverse this?',
    THIS_WILL_REVERSE_SYNC_LOG_FILE:
        'This will reverse sync sale file to previous version',
    sale_log_warnings: 'Sale Warnings',
    REALY_SURE_REVERSE_THIS_SALE: 'Are you really want to reverse this?',

    CUT_ITEMS_AVAILABLE: 'Cut items available',
    NO_CUT_ITEMS: 'No cut items available',
    CUT_NO: 'Cut No',
    CANNOT_CHANGE_BRANDS_AVAILABLE:
        'Cannot change, brand boxes available inside sub category box',
    CANNOT_CUT_BRANDS_AVAILABLE:
        'Cannot cut, brand boxes available inside sub category box',
    CUT_LIST_WILL_BE_DELETED:
        'Cut boxes will be removed, Are you sure to continue?',

    archived_type: 'Archived Type',
    ARCHIVEDD: 'Archived',
    NOT_ARCHIVED: 'Not Archived',

    MP_CHART_TITLES: { space: 'SPACE', sales: 'SALES', profit: 'PROFIT' },
    FILTER_BY_DATE: 'Filter By Date',
    FILTER_ITEMS: {
        title1: 'Select date range',
        startdate: 'Start Date',
        enddate: 'End Date',
        buttontitle: 'Filter',
    },
    CHART_MSG: {
        no_cat: 'No category data available',
        no_subcat: 'No subcategory data available',
        no_brand: 'No brand data available',
        no_rule: 'No rule data available',
    },
    data: 'Data',
    rules: 'Rules',

    close_shelvedrawview_first: 'Close shelves select view first',
    close_rulesview_first: 'Close rules update view first',
    unallocated: 'Unallocated',
    select_eye_level_to_continue: 'Select an eye level to continue',
    no_field_rules_available: 'Field not selected rules available',
    no_shelves_rules_available: 'Shelves not selected rules available',
    shelf_is_in_othercategory: 'Shelf is in another category',
    cannot_change_morethan_100: 'Cannot change more than 100%',
    updatefield_details: 'Update Category Field',
    NO_CONTENT_FOUND: 'No content found',
    EYE_LEVEL: 'Eye Level',
    ON_TOP: 'On Top',
    cannot_redirect_without_boxes:
        'Cannot redirect sub category without sub category boxes',
    SELECT_RULE_FIELD: 'Select rule field',
    REMOVE_FIELD_FROM_CATEGORY: 'Remove display unit from category',
    SURE_TO_REMOVE_FIELD_FROM_CATEGORY:
        'Are you sure to remove this display unit from this category?',
    shelf_is_already_assign_to_otherrect:
        'Shelf is already assigned to other rect',
    shelf_is_contain_subcategories: 'Shelf is contains sub categories',
    NO_PRODUCTS_AVAILABLE: 'No products available',
    no_selected_contain_shelfs_available:
        'No selected contain shelves available',
    FACING: 'Facing',
    IS_MANDATORY: 'Is Mandatory',
    PRODUCT_NEEDS_TOBE_MVP:
        'Product needs to be an MVP product to select mandatory',
    BRAND_PRODS_UPDATED: 'Successfully brand product details updated',
    PRODUCT_NOT_FOUND: 'Product details not found',
    cannot_change_mandatory_to_normal:
        'Cannot change mandatory product rank to normal product rank',
    cannot_change_normal_to_mandatory:
        'Cannot change normal product rank to mandatory product rank',
    cannot_change_hastags_to_normal:
        'Cannot change tag product rank to normal product rank',
    cannot_change_normal_to_hastags:
        'Cannot change normal product rank to tag product rank',
    multiple_category_boxes_available: 'Multiple category boxes available',
    subcategories_available_in_rects:
        'Sub categories available in category boxes',
    NO_CHANGES_AVAILABLE: 'No changes available',
    NO_ARCHIVE_ITEMS: 'No archive items available',
    ARCHIVE_SELECTED_PRODS: 'Archive selected products',
    ARE_YOU_SURE_TO_ARCHIVE: 'Are you sure to archive this selected products?',
    SAVE_PRODUCT_BEFORE_EDIT:
        'Save current product changes before edit product details',
    CHANGE_NOTBE_SAVED: 'Changes you made may not be saved',
    USED_AS_RULE_IN: 'Used as a rule in',
    LEVEL: 'Level',
    THIS_BRAND_IS_ALREADY_ADDED_RULE:
        'This brand is already added as a rule in',
    THIS_SCAT_IS_ALREADY_ADDED_RULE:
        'This sub category is already added as a rule in',
    PLACES: 'places',

    NEW_PROD_ARCHIVE_CONFIRM: 'Are you sure to archive this product?',
    NEW_PROD_SENDTODEP_CONFIRM:
        'Are you sure to send this product to master planogram?',
    NEW_PROD_RESTORE_CONFIRM: 'Are you sure to restore this product?',
    CATELOG_IMP_NAME: 'Catalog Import Name',

    PROD_SAVE_CONFIRM_SENTTODEP:
        'This product will be sent to the master planograms. Are you sure to continue?',
    PROD_SAVE_CONFIRM_NOT_SENTTODEP:
        'This product will be not sent to the master planograms. Are you sure to continue?',
    isarchived: 'Is Archived',
    productedit_warning: 'Product Edit Warnings',
    export_to_vmp: 'Export To VMP',
    SIMULATE_CONVERT_VMP_CONFIRM: 'Simulate convert to VMP',
    SIMULATE_CONVERT_VMP_CONFIRM_MSG:
        'Are you sure to convert simulate object to VMP object?',
    SIMULATE_SAVE_CONFIRM: 'Save simulate category changes',
    SIMULATE_SAVE_CONFIRM_MSG:
        'Are you sure to save this simulate category changes?',
    NOT_SIM_PRODUCTS: 'Non Simulated',
    CATEGORY_DETAILS: 'Category Details',
    SUB_CATEGORY_DETAILS: 'Sub Category Details',
    NO_RULES_AVAILABLE: 'No rules available',
    RULE_PER: 'Rule %',
    SIMULATED_PER: 'Sim %',
    SIMULATE_SAVE_SUCCESS: 'Simulate category successfully saved',
    NO_CLIPBOARD_ITEMS: 'No clipboard items found',

    NO_REASON: 'No Reason',
    HEIGHT_NOT_ENOUGH: 'Height Not Enough',
    MIN_QTY: 'Min Quantity',
    NO_TAG_MATCHING: 'No Tag Matching',
    NOT_ENOGH_SPACE: 'Not Enough Space',

    PLS_ENTER_VALID_PERCENTAGE: 'Please enter a valid percentage value',

    CATELOGUE_IMPORT: 'Catelogue Import Log',
    CATELOGUE_FILTERS: {
        type: 'Type',
        issueType: 'Issue Type',
        status: 'Status',
        from: 'From Date',
        end: 'End Date',
        todate: 'To Date',
        search: 'Search',
        reset: 'Reset',
        resolve: 'Resolve',
        approve: 'Approve',
        unresolve: 'Unresolve',
        colDetails: 'Details/Description',
        dateReported: 'Date Reported',
        issue: 'Issue',
        filename: 'File Name',
        created: 'Imported Date',
        errortype: 'Error Type',
        resolved: 'Resolved',
    },
    DEPARTMENT_IMPLEMENT_FILTERS: {
        status: 'Status',
        colDetails: 'Details/Description',
        storeName: 'Store',
        department: 'Department',
        masterPlanogram: 'Master Planogram',
        createdDate: 'Implementation Date'
    },
    FILE_ERROR_LOG: 'File Error Log',
    CATELOGUE_DASHBOARD: {
        allCount: 'All Count',
        confirmPending: 'Confirm Pending',
        needUserInput: 'User Attention Needed',
        planigoCompleted: 'Completed',
        allCompleted: 'All Complete',
        fileCurruptedCount: 'Files Corrupted',
        dataErrorCount: 'Data Errors',
        saveErrorCount: 'Save Errors',
    },
    DEPARTMENT_IMPLEMENT_DASHBOARD: {
        failed: 'Failed',
        inProcess: 'In Process',
        inQueue: 'In Queue',
        planigoCompleted: 'Completed',
    },
    CATELOGUE_ERR_MARK_ITEMS: 'Please select one or more items',
    CATELOGUE_ARE_YOU_SURE_CONT_APPROVE_MSG:
        'Are you sure you want to continue to approve the marked items?',
    CATELOGUE_ARE_YOU_SURE_CONT_RESOLVE_MSG:
        'Are you sure you want to continue to resolve the marked items?',
    CATELOGUE_SYNC_MSG: 'Are you sure to sync?',
    LOG_DETAILS: 'Log Details',

    CatelogImportLogTypes: {
        All: 'All',
        Department: 'Department',
        Category: 'Category',
        SubCategory: 'Sub Category',
        Brand: 'Brand',
        Supplier: 'Supplier',
        Product: 'Product',
        Other: 'Other',
    },
    CatelogImportIssueTypes: {
        PlanigoMapping: 'Planigo Mapping',
        ArigoMapping: 'Arigo Mapping',
        NotEnoughData: 'Not Enough Data',
        NoIssue: 'No Issue',
        MappingIssue: 'Mapping Issue',
    },
    CatelogImportLogStatus: {
        All: 'All',
        ConfirmPending: 'Confirm Pending',
        NeedUserInput: 'Need User Input',
        PlanigoCompleted: 'Completed',
        AllCompleted: 'All Completed',
        Other: 'Other',
    },

    DepartmentImplementLogStatus: {
        all: 'All',
        failed: 'Failed',
        ongoing: 'In Process',
        in_queue: 'In Queue',
        completed: 'Completed',
    },

    FileImportErrorLogType: {
        None: 'None',
        All: 'All',
        FileCurrupted: 'File Corrupted',
        DataError: 'Data Error',
        SaveError: 'Save Error',
        Other: 'Other',
    },

    FileImportErrorLogFilterTypes: {
        All: 'All',
        Department: 'Department',
        Category: 'Category',
        SubCategory: 'Subcategory',
        Brand: 'Brand',
        Supplier: 'Supplier',
        Product: 'Product',
        Other: 'Other',
    },
    RESOLVE_NOTE: 'Resolve Note',
    NEW_SUB_CAT: 'New Subcategory ',
    NEW_BRAND: 'New Brand',
    NEW_SUPPLIER: 'New Supplier',
    SUGGESTED_NAME: 'Suggested Name',
    SUGGESTED_DEPARTMENT: 'Suggested Department',
    SUGGESTED_CATEGORY: 'Suggested Category',
    SUGGESTED_SUPPLIER: 'Suggested Supplier',
    PROD_SUGGESTED_BRAND: 'Suggested Brand',
    PROD_SUGGESTED_SUBCAT: 'Suggested Subcategory',
    SUGGESTION_NOTE: {
        first: 'Please approve or ignore the conflicts carefully.',
        second: 'If you approve, the suggested information will be saved to the relavant record.',
        third: 'If you ignore a conflict, information will not be updated.',
    },
    APPROVE_CHANGES_NOTAVL_MSG:
        'There are no changes accepted, if you continue, the record will save as it is, are you sure to continue?',
    APPROVE_CHANGES_AVL_MSG:
        'There are accepted changes, if you continue, it will update the record data, are you sure to continue?',
    CATELOG_LOG_EX_LABLES: {
        department: 'Current Department',
        category: 'Current Category',
        supplier: 'Current Supplier',
        brand: 'Currrent Brand',
        subcategory: 'Current Subcategory',
        name: 'Current Name',
    },
    CONFLICT_TITLE: 'Conflicts Available',

    CATELOG_NEW_INFO_LABELS: {
        new_scat_title: 'New Subcategory',
        subcat: 'Create a new Subcategory ',
        subcat_already_exist_msg:
            'If already exists, Please select an existsing Subcategory from the list.',

        new_brand_title: 'New Brand',
        brand: 'Create a new Brand ',
        brand_already_exist_msg:
            'If already exists, Please select an existsing brand from the list.',

        new_sup_title: 'New Supplier',
        supplier: 'Create a new Supplier ',
        sup_already_exist_msg:
            'If already exists, Please select an existsing supplier from the list.',
    },
    STORE_PRODUCTS: 'Sensitivity',
    sensitiveValue: 'Sensitive Value',
    updateSensitiveValue: 'Update Sensitive Value',
    actualStoreState: 'Actual Store State',
    Add_Store_Product: 'Add Product',
    store_products_currently_view_txt: 'You are currently viewing',

    CONFIRM_TO_CHANGE_BRANCH_MANAGER: 'Confirm to change branch manager',
    CONFIRM_TO_CHANGE_BRANCH_MANAGER_DETAILS:
        'This user can be used in other branch, Are you sure to change this user as branch manager?',
    CONFIRM_TO_DELETE_BRANCH: 'Confirm to delete a branch',
    CONFIRM_TO_DELETE_BRANCH_DETAILS: 'Are you sure to delete this branch?',

    SNAPSHOT_AVL_MODAL_TITLE: 'Snapshots Available',
    SNAPSHOT_AVL_MODAL_CHECKBOX_LABEL: 'Update Snapshots',

    SOME_SUBCAT_NOBOXES:
        "Some sub categories don't have any boxes, Draw boxes or remove them first",
    SOME_BRAND_NOBOXES:
        "Some brands don't have any boxes, draw boxes or remove them first",
    NO_NAVIGATION_ITEMS_FOUND: 'No navigation items found',
    CLEAR_SNAPSHOTS_MSG:
        'This department VMP is changed. This simulation is no longer accurate to the VMP. Do you want to clear all snapshots?',
    CLEAR_SNAPSHOTS: 'Clear Snapshots',
    CONFIRM_TO_CLEAR_SNAPSHOT: 'Confirm to clear snapshots',
    CONFIRM_TO_CLEAR_SNAPSHOT_MSG:
        'Are you sure to clear all simulation snapshots?',
    DROP_PROD_FROM_ONTOPNEW: 'Drop products in this section from on-top or new',
    DROP_PROD_FROM_MVPNEW: 'Drop products in this section from MVP or new',
    SNAPSHOT_AFFECT_MSG: 'This task will affect the saved snapshot. ',
    SNAPSHOT_EXTRA_MSG: '(This will only reset the product ranks.)',

    CLICK_TO_EDIT: 'Click here to edit',
    Full_Screen: 'Full Screen',
    REQUIRED: 'Required',
    DRAWED: 'Drew',
    SAVE_FIELD_INRULE: 'Save Field In Rule',
    cannot_remove_middle_shelf:
        'Cannot remove from the middle of contain shelves',
    cannot_select_between_othershelfs: 'Cannot select between other shelves',
    contains_shelfs_between_othershelfs:
        'Contain shelves between other shelves available',

    rect_draw: 'Rectangle Draw',
    youre_nowediting: 'you are now editing the',
    items: 'Items',

    unallocated_space: 'Unallocated Space',
    imageSource: 'Image Source',

    iscombinefilter: 'Is combine filtering',
    range: 'Range',
    missingtypes: {
        dimension_missing: 'Dimension Missing',
        image_missing: 'Image Missing',
        brand_missing: 'Brand Missing',
        subcategory_missing: 'Sub Category Missing',
        category_missing: 'Category Missing',
        department_missing: 'Department Missing',
        entire_hierarchy_missing: 'Entire Hierarchy Missing',
    },
    MVP: 'MVP',
    MVPnOPNTOP: 'MVP & ONTOP',
    ONTOP: 'ON TOP',
    MM: 'mm',

    MAX: 'Max',
    MIN: 'Min',
    NotMVPONTOP: 'Not Mvp & Not OnTop',
    SHOW_RESULTS: 'Results',

    DATA_MISSING: 'Data Missing',
    FULL_DATA: 'Full Data',
    completeStatus: 'Complete Status',
    custom: 'Custom',
    current_version: 'Current version',
    required_validmvp: 'MVP percentage cannot be less than 0 or more than 100',
    per_morethan_fiveper: 'Percentage more than 5% categories not found',
    fields_notadded_categories: 'Fields not added categories available',

    editDepartment: 'Edit Department',
    is_hidden: 'Is Hidden',
    parent_department: 'Parent Department',
    parent_category: 'Parent Category',

    RESOLVE_HIERACHY: 'Resolve Hierarchy',
    IMPORT_NAME: 'Import Name : ',
    RESOLVED_DATE: 'Resolved Date',
    ARE_YOU_SURE_TO_RESET: 'Are you sure to reset?',
    ARE_YOU_SURE_TO_RESOLVE: 'Are you sure to resolve?',

    is_hiddendepartment: 'Ignore Hidden Departments',
    hidden_department: 'Is Hidden Department',
    hidden: 'Hidden',
    filter_datetype: {
        range: 'Date Range',
        count: 'Date Count',
        backdays: 'Sale Days Count',
    },
    PLEASE_SET_VALID_DATE_COUNT: 'Please Set a valid Date Count!',
    multiselect: {
        all: 'Select All',
        unselect: 'Unselect All',
        allaction: 'All Action',
        sendtodep: 'Send to Dep All',
        archive: 'Archive All',
        restore: 'Restore All',
        sendall: 'Send All',
        sendselected: 'Send Selected',
        archiveall: 'Archive All',
        archiveselected: 'Archive Selected',
        restoreAll: 'Restore All',
        restoreSelected: 'Restore Selected',
        onlythisprod: 'Only This Product',
    },
    product_type: 'Product Type',
    product_source: 'Product Source',
    Other: 'Other',

    PRODS_SENDTODEP_CONFIRM:
        'Are you sure to send this products to master planogram?',
    PRODS_RESTORE_CONFIRM: 'Are you sure to restore this products?',
    SENT_DEPPRODS: 'Sended to department products',
    ARCHIVE_PRODS: 'Archived Products',
    RESTORED_PRODS: 'Restored Products',
    RESPONSE_STAT: { updated: 'Updated', failed: 'Failed' },

    THIS_ACTION_WILL_DELETE_THIS_DEPARTMENT_SURE:
        'This action will delete this department and its categories and subcategories.Are you sure?',
    THIS_ACTION_WILL_DELETE_THIS_CATEGORY_SURE:
        'This action will delete this category and its subcategories.Are you sure?',
    SELECTTO_CHANGE_DEP: 'Select to change department',
    SELECT_TO_CHANGE_CAT: 'Select to change category',
    suretochangeparent: 'Are you sure to change parent?',
    THIS_ACTION_WILL_CHANGE_PARENT_CURRENT_TO_NEW:
        'This action will change current parent to new',
    THIS_ACTION_WILL_DELETE_THIS_SUBCATEGORY_SURE:
        'This action will delete this sub category. Are you sure?',

    NO_SELECTED_PRODUCTS: 'No selected products available',

    WARNING_S: 'Warning !',
    THIS_CHANGES_MAYBE_CANNOT_BACKTO_VMP:
        'Simulation changes your going to do here may cannot be convert back to VMP.',

    MANUALLY_FIXED: 'Manually Fixed',
    AUTO_PROCESSED: 'Auto Processed',
    SELECT_PRODUCTS: 'Select Products',
    resolvelist: 'Resolve List',
    allissues_noprods: 'Please add products to added resolve list to continue',
    noprods_issuesavalable:
        'No products added resolve items available, Those are not will be send to update',

    CURRENTLY_OPEN_CONFLICTS: 'Current Open Conflict',
    SELECTED_PRODUCT_COUNT_TXT: ' Products Selected',
    COLLAPSE_ALL: 'Collapse All',
    HIDE_ALL: 'Hide All',
    PRINT_SIMULATION: 'Print Simulation',

    ARCHIVED: 'archived',
    SENDTODEP: 'sent to department',
    RESTORED: 'restored',

    TAKESOMETIMETO_COMPLETE:
        'It will take some to complete this task. App will notify you when it is completed',
    SELECTED_PRODS_AVAILABLE:
        'Only this product is going to apply this change, Selected product will be not affected',

    WATCH: 'Watch',
    MAPPING: 'Mapping',
    GOT_IT: 'Got It!',
    no_sales: 'No Sales',
    below_min_rev: 'Below Minimum Revenue',
    no_sales_in_selected_date_range: 'No sales between dates',

    COLORS_NOT_AVAILABLE: 'No colors available',
    PICK_CUSTOM_COLOR_TXT: 'Pick a Custom Color',
    USED_COLORS: 'Used Colors',
    REASON: 'Reason',
    CHANGE_PARENT: 'Change parent',
    PAGE: 'Page',
    Destributer: 'Destributer',
    Shelf_unit_no: 'Shelf Unit No',
    Field_unit_no: 'Field Unit No',
    NO_LONGER_VALID: 'No Longer Valid',
    AFFETCED_CHANGES: 'Affected Changes',
    isPrioratizeUserPercentage: 'Fixed Percentage',
    depcatcannot_be_empty: 'Please select a category to switch!',
    please_select_a_dep_to_switch: 'Please select a department to switch!',

    PROD_DIMENTIONS_NOTAVAILABLE: 'Product dimentions not correctly added',
    NONUSED_PROD_PRINT: 'Export Non Used Products',
    BULKQR_PRINT: 'Bulk QR Print',
    BULKQRPRINT_MODAL: 'Bulk Field QR Print',
    ALL_LAYOUT: 'Entire Layout',
    DEPARTMENT_WISE: 'Department Wise',
    EXPORT: 'Export',
    FIELDS_AVAILABLE: 'fields available',
    NOFIELDS_AVAILABLE: 'No fields available',

    IS_stackable: 'Is Stackable',
    maxstackablecount: 'Max Stack Count',
    Change_Stackable_state_of_all_products:
        'Change stackable state of all products',
    STACKABLE: 'Stackable',
    NONSTACKABLE: 'Non-Stackable',
    MARK_PRODUCTS: 'Mark Products',
    CHANGE_ALL_PRODUCTS_TO_NEW_MARKED_STATE:
        'Change all products to new marked state',
    THIS_WILL_AFFECT: 'This will affect ',
    PRODUCTS_ARE_YOU_SURE: ' products. Are you sure to continue?',

    ShowStackable: 'Show Stackable',
    HideStackable: 'Hide Stackable',
    Mark_stackable_products: 'No changes in mark stackable products!',
    Max_Stack_count_zero_or_empty_means_no_stack_count:
        "Leave this field blank or 0 if you don't want to limit stackable count",
    There_are_unsaved_changes: 'There are unsaved changes',
    Your_last_changes_not_saved_How_do_you_want_to_continue_print:
        'Your last changes not saved.How do you want to continue print?',
    SavenPrint: 'Save & Print',
    Print_Without_save: 'Print Without save',
    Last_changes_not_saved_how_do_you_want_to_continue_print:
        'Your last changes not saved.How do you want to continue print?',

    DISPLAY_UNIT: 'Display Unit',
    NO_FIELDS_FOUND_TOPRINT: 'No fields available for print',
    NO_SELECTED_FILES: 'No Selected Files Available',

    GS1_Override: 'GS1 Override',
    DIMENSION: 'Dimensions',
    Image_Available: 'Image Available',
    New_Image_Available: 'New Image Available',
    No_Image_Available: 'No Image Available',
    Current: 'Current',
    THIS_PROD_HAVENT_GS1_UPDATE_AVAILABLE:
        "This product doesn't have a GS1 details available.",
    UPDATE_ALL: 'Update All',
    UPDATE_ALL_DIMENSION: 'Update All Dimensions',
    Activate_Confirmed_Planograms: 'Activate Confirmed Planograms',
    Select_from_available_confirmed_stores_list_and_activate:
        'Select from available confirmed stores list and activate',
    No_Data_Available: 'No data available',
    Search_stores: 'Search stores',
    Successfully_planograms_activated: 'Successfully planograms activated',
    click_at_least_one_store: 'click at least one store',
    PROD_IMAGE_NOTFOUND: 'Product image not found',

    ACTIVE: 'Active',
    CONNECTED: 'Connected',
    DISCONNECTED: 'Disconnected',
    IMPLEMENT: 'Implement',

    ACTIVATE_OVERRIDE_TOCONTINUE: 'Activate override option first',
    Create_new_AUI_version: 'Create new AUI version',
    You_have_unsaved_changes_please_save_before_continue:
        'You have unsaved changes. Please save before continue',
    you_sure: 'Are You Sure?',
    this_will_create_new_aui_version: 'This will create a new AUI version',
    You_are_already_have_planogram_version:
        'You are already have planogram version',
    Please_select_what_to_do: 'Please select what to do',
    Add_this_one_also: 'Add this one also',
    Keep_as_a_draft: 'Keep as a draft',

    TOCONNECT_AND_OVERIDE: 'to connect and override current version',
    CONTINUE_WITHOUT_THEM: 'Continue without them',
    BACK_TO_EDIT: 'Back to edit',
    YOURE_ABOUT_TO_IMPLEMENT: 'You are about to implement a planogram.',
    THIS: 'This',
    NOT_CONNECTED: 'are not connected',
    NO_AUI_VERSIONS: 'No Implementation versions found',
    LETS_GO: "Let's Go",
    MORE_TAGS: 'More Tags',
    THIS_SELECTION_CONTAINS_TAGED: 'This selection contains tagged branches',
    DO_YOU_WANT_TO_INGNORE: 'Do you want to ignore that?',
    SEVERAL_DISCONNECTED_STORES:
        'There are several disconnected stores in this selection',
    IMPLEMENT_WILL_OVERRIDE:
        'This implementation will override selected store planogram',
    IMPLEMENT_CANNOT_CONTINUE:
        'The implementation cannot continue in one and more branches',
    PLEASE_ASSIGN_CAT_LOCATION:
        'Please assign category location to the display units',
    ITS_OKAY: "It's Okay",
    BACK_TO_SIM: 'Back to simulation',
    ALL_BRANCHES_CONNECTED: 'All branches are connected',
    FIXED_PER: 'Fixed %',
    SALE_CYCLE: 'Sale Cycle',
    NO_TAG: 'No Tag',
    FIELD: 'Field',
    DUPLICATE_VMP: 'Duplicate VMP',
    VIEW_VMP: 'View VMP',
    VIEW_AUI: 'View AUI',
    NO_ACTIVE_PLANOGRAMS: 'No active planograms found',
    VMP_AUI_LOCKED:
        'This is only in view mode. (This VMP version is converted to AUI)',
    NO_VERSIONS_AVAILABLE: 'No Versions Available',
    STARTEND_DATES_REQUIRED: 'Start and end dates required',
    ENDDATE_LOWERTHAN_STARTDATE: 'Start date should be prior to the end date',
    ENTER_DEPT_RULES_UPDATE: 'Enter department rules and update',
    IMPLEMENT_PROCESS_STARTED: 'Implementation process successfully started',
    NOTIFY_AFTER_COMPLETE: "It'll notify after complete the process",

    FRESH_MODAL:
        'You get a Fresh planogram version you can see it in the planograms list',
    NEW_VERSION_EDIT: 'Enter version name and update department meta data',
    Edit_cat: 'Edit Category',
    MINIMIZE_Screen: 'Minimize Screen',
    PLEASE_SAVE_BEFORE_FIRST: 'Please save first',
    Reset_Changes: 'Reset Changes?',
    this_will_reset_your_unsaved_actions_are_you_sure_to_continue:
        'This action will reset your unsaved changes. Are you sure to continue?',

    THIS_IS_YOUR: 'This is your',
    TYPE_HERE_TO_SEARCH: 'Type here to search',
    NO_FIELDS: 'No Fields',
    WE_NOTICE_THAT_NO_FILLED:
        'We notice that in this store list there is no field count. You can edit now or later.',
    EDIT_FIELDS_NO: 'Edit fields number',
    FOLLOW_DUNIT_WARN:
        "The following branches in your selection have unique display units; let's fix that.",
    FIELD_ARRANGEMENT_NOTMATCH: 'Field arrangement doesn’t match',
    NO_COUNT_ADDED_FIELDS:
        'The field count is not updated in any of the stores. Please update field counts to continue',
    UPDATE_CHANGES: 'Update Changes',
    ARE_YOU_SURE_NOFIELD: 'Are you sure to update this store field counts?',
    UNIQUEFIX_BTNS: {
        SAVE: 'Save Updates',
        IGNORE: 'Ignore All',
        FORCE: 'Force',
        FORCEALL: 'Force All',
        EXCLUDE: 'Exclude',
        EXCLUDEALL: 'Exclude All',
    },

    MP_IMPLEMENTATION: 'Master planogram implementation',
    NO_NOTIFICATIONS_FOUND: 'No notifications found',
    ARE_YOU_SURE_DIFFFIELD: 'Are you sure to update this stores?',
    ZERO_FIELD_NUMBER_STORES: 'Zero field number stores will be ignored',
    DIFFFIELD: {
        WIDTH: 'Different Field Structure (Width, Depth)',
        SHELF: 'Different Field Structure (Different Shelf)',
    },

    ENTER_PASSWORD_PLS: 'Enter password to delete',

    LESS_PRD:
        'Less than 4 products simulated / no sales quantity available at least for 4 products',
    TAKE_BACK: 'Take Back',
    CONT_TAKE_BACK: 'Continue simulation take back',
    TACKBACK_SUCCESS: 'Simulation take back successfully completed',
    DISCONNECTED_SUCCESS: 'Disconnected successfully',
    TACKBACK_BLOCKED: 'Take back to parent blocked',
    posCompleteStatus: 'Hierarchy Details',
    posStatusList: {
        NO_DATA: 'No Data',
        HAVING_DATA: 'Having Data',
        INVALID_DATA: 'Invalid Data',
        OTHER: 'Other',
        DATA_ISSUES: 'Data with Issues',
    },
    Total: 'Total',
    Successfully_products_updated: 'Successfully products updated',
    Excel_file_examples: 'Excel file examples',
    Import_xlsx_files_with_these_formats:
        'Import .xlsx files with these formats',
    Template: 'Template',
    TakeTime:
        'It will take some time to complete this task. The app will notify you when it is completed.',
    ACTION: 'Action',
    product_doesnt_exist: "product doesn't exist",
    Excel_Import_Bulk_Update_Details: 'Excel Import Bulk Update Details',

    AISLE: 'Aisle',
    ROTATE_OVERLAPPING_MESSAGE: 'Overlapping!',

    STORE_WITH_ISSUES: 'Stores with issues',
    PLEASE_FIX_BELOWITEM:
        'Please fix the below store list missing floor and store manager issues to continue',
    ISSUESTORE_BTNS: {
        available: 'Available',
        notavailable: 'Not Available',
        ignoreall: 'Ignore All',
        retry: 'Retry',
    },
    CAN_BE_CONTINUE_WITHOUT: 'can be continue without issues',
    NO_REPLACED_PRODS_AVAILABLE: 'No replaced products available',

    PROD_SOURCETYPES: {
        Manual: 'Manual',
        POS: 'POS',
        Arigo: 'Arigo',
        GS1: 'GS1',
        Other: 'Other',
    },
    IS_BLOCK: 'Is Block',
    BLOCK_UNTIL_DATE: 'Block Until Date',
    SELECT_DATE: 'Select a date',
    UPLOAD_PRODUCT_ACTION: 'Upload Product Action',
    Select_import_Product_excel_files: 'Select and import Product excel files',
    FILE_SELECTED: 'file selected',
    Click_and_select_excel_file: 'Click and select excel file',
    OR: 'or',
    Drag_and_drop_excel_file_here: 'Drag and drop excel file here',
    Excel_product_list: 'Excel product list',
    Block_Products: 'Block Products',
    Archive_Products: 'Archive Products',
    Previews_selected_file_barcodes: 'Previews selected file barcodes',
    No_file_selected_yet: 'No file selected yet',
    Planogram_Store_Copy: 'Planogram Store Copy',
    Select_Region: 'Select region',
    Select_A_Region: 'Select a region',
    select_Store: 'Select store',
    select_a_Store: 'Select a store',
    successfully_copied: 'Successfully copied',
    Add_Department: 'Add Department',
    Add_least_one_department: 'Add least one department',
    SEPERATE_GROUP: 'Separate group',
    DISCONNECTED_GROUP: 'Disconnect group',
    GROUP_SORT: 'Group Sort',

    SORT_BY: 'Sort By',
    APPLY_TYPES: { ALL: 'All', USED: 'Used', SELECTED: 'Selected' },
    GROUP_PRODS: 'Group Products',
    GROUP_CLOSE: 'Group Close',
    MAKE_GROUP: 'Make Group',
    NO_PRODS_TO_GROUP: 'No products available to group',
    PROD_NOTIFICATIONS: 'Product Notifications',
    any_brand: 'Any Brand',
    any_supplier: 'Any Supplier',
    any_heirarchy: 'Any Hierarchy',
    any_category: 'Any Category',
    any_subcategory: 'Any Sub Category',
    any_department: 'Any Department',
    SELECT_MORETHAN_ONEPRODS: 'Select more than one products to continue',
    SELECT_PRODSTO_CONTINUE: 'Select products to continue',
    TEST_DAYS: 'Test Days',
    STRGY_TYPES: {
        linear: 'Linear',
        replace: 'Replace',
        maxfaceprod: 'Max face product',
        minsaleprod: 'Min sale product',
        maxfaceminsale: 'Max face with min sales',
        attached: 'Attached',
        block: 'Block',
    },
    NEWTESTINGPRODS: 'New Testing Products',
    NEWPENDINGPRODS: 'New Pending Products',
    IS_APPLIED: 'Applied Products',
    IS_ONGOING: 'Ongoing Products',
    SELECTED_PRODUCTS: 'Selected Products',
    SEARCHED_PRODUCTS: 'Searched Products',
    NO_NEW_PRODS: 'No New Products Available',
    CHOOSE_FROM_LIST: 'Choose from list',
    UNUSED: 'Unused',
    SELECT_SELECTOPTION: 'Some items selection option is not selected',
    SELECT_REPLACE_PRODS: 'Some items missing replacing products list',
    SELECT_FACINGVALUES: 'Some items facing/percentage value not updated',
    SEARCH_PROD_PLACEHOLDER: 'Search product (name/barcode)',
    GROUPDETAILS_NOT_MATCHING:
        'Product details not matching with other products',
    SAVING: 'Saving...',
    CANCEL_SELPRODS: 'Cancel Applied Products',
    PLEASE_SELECT_CANCELOPTION: 'Please select a cancel option to continue',
    singleprodcancel: {
        old: 'Cancel back to old product status',
        newpending: 'Cancel back to new pending status',
    },
    CANCEL_PRODSTATE: 'Cancel Product',
    SURETO_CANCEL_OLD: 'Are you sure to cancel product to old state?',
    SURETO_CANCEL_NEWPENDING:
        'Are you sure to cancel product to new pending state?',
    SUCCESSFULLY_CANCELLED: 'Successfully Canceled',
    REMOVE_PRODGROUP: 'Remove Product Group',
    ARE_YOU_SURE_TO_CONTINUE_REMOVEGROUP:
        'Are you sure to remove this product group?',
    ALREADY_REPLACEPROD_ADDED: 'Replace product already selected',
    REMOVE_SELECTED: 'Remove Selected',
    ENTER_VALIDTESTPERIOD_VALUE: 'Enter valid test period value',
    ENTER_VALIDTES_Min_Facing_VALUE: 'Enter valid min facing limit value',

    REPLACE_IMPACT: 'Replace Impact',
    LETS_TRYIT_COUPLEMORE_DAYS:
        "Let's try it for couple more days and we'll decide together what to do",
    GIVE_IT: 'Give it',
    MORE_TIME: 'more time!',
    KEEP: 'Keep',
    ARISTO_NOTIFICATIONS: "Aristo's Notifications",
    NEWPROD_NOTTYPES: {
        TestPeriodOver: 'Test period is over',
        RisingStar: 'Rising Star',
        NewStarIsBorn: 'New Star is born',
        InOut: 'In & Out',
        FOutOfShelf: 'F-Out of shelf',
        DeadWalker: 'Dead Walker',
    },
    NewProductStrategyLevel: {
        Brand: 'Brand',
        Cat: 'Cat',
        Sub: 'Sub',
        Dep: 'Dep',
    },
    STRGY_TYPES_Content: {
        linear: 'Linear mode will allocate given space in the brand level by adjusting current product with linearly.',
        replace:
            'You can select specific product to replace new product. Most top products are the best to replace.',
        maxfaceprod:
            'This will select a replace product from each store(from active branch planogram) with max facing.',
        minsaleprod:
            'This will select a minimum sales product for each store in the given time range.',
        maxfaceminsale:
            'This will select a best product to replace by ordering products into max facing and min sales.',
        attached: 'Attached',
        block: 'This will select blocked products in the brand level in every store.',
    },
    NEWPROD_LOGS: 'New Product Logs',
    NEWPROD_LOG_START: 'Start Log',
    NEWPROD_LOG_CHANGES: 'Changes Log',
    NEWPROD_START: {
        changeOption: 'Change Option',
        testPeriod: 'Test Period',
        appliedDate: 'Applied Date',
        storeCount: 'Store Count',
        shouldStoreCount: 'Should Exist Store Count',
        salesStoreCount: 'Sales Available Store Count',
        testStartDate: 'Test Start Date',
        testEndDate: 'Test End Date',
        doneDate: 'Task Done Date',
        mode: 'Mode',
    },
    NEWPROD_CHANGES: {
        mode: 'Mode',
        action: 'Action',
        changeDate: 'Change Date',
        expandCount: 'Expand Count',
    },
    any_mode: 'Any Mode',
    any_action: 'Any Action',
    any_changeoption: 'Any Change Option',
    NP_MODELIST: {
        auto: 'Auto',
        manual: 'Manual',
    },
    NP_ACTIONLIST: {
        remove: 'Remove',
        keep: 'Keep',
        expand: 'Expand',
    },
    NP_CHANGEOPTIONS: {
        opt_a: 'Option A',
        opt_b: 'Option B',
        opt_c: 'Option C',
        opt_d: 'Option D',
        opt_e: 'Option E',
    },
    CONFIRM_TO_AUTOSYNC: 'Confirm Auto Sync',
    SALES: 'Sales',
    TESTTYPES: {
        ANY: 'Any Type',
        ONGOING: 'Ongoing',
        ENDED: 'Test Period Ended',
        PENDING: 'Pending',
    },
    TESTPERIOD_SUCCESSFULLY_UPDATED: 'Test period successfully updated',

    ITEMS_SELECTED: 'items selected',
    CLOSE_GROUPVIEW_TOCONTINUE: 'Close group view to continue',
    GROUP_ITEM: 'Group Item',
    ITEMS_AVAILABLE: 'items available',
    SURE_TO_REMOVE: 'Are you sure to remove?',
    SURE_TO_KEEP: 'Are you sure to keep?',
    ARE_YOU_SURE_TO_KEEP_THIS_PRODUCT: 'Are you sure to keep this product',
    ARE_YOU_SURE_TO_REMOVE_THIS_PRODUCT: 'Are you sure to remove this product',
    PRODUCT_SUCCESSFULLY_REMOVED: 'Product was successfully removed.',

    LETS_START_TESTPERIOD: "Let's start test period",
    TEST_PERIOD_COMPLETED: 'of test period days completed',
    TEST_START_OPTION: 'Test Start Option:',
    PRODUCT_SUCCESSFULLY_KEEP: 'Product kept successfully.',
    Mandotoryoption: {
        newmandatory: 'New Product/Group change as Mandatory',
        oldremovemandatory: 'Remove Mandatory of Replaced Products',
        replaceoldmandatory: 'Keep as it is',
    },
    this_mandotory_please_select_option_to_continue:
        'This product is a mandatory product. Please select an option to continue',
    MandatoryOptions: 'Mandatory Options',
    NP_STARTLOG_SYNC_SUCCESS: 'New Product Log Sync Success',

    ARISTO_MAPS: "Aristo's Maps",
    VERSIONANALYSIS: 'Version Analysis',
    FAST: 'Fast',
    SLOW: 'Slow',
    OPEN_SEARCH: 'Open Search',
    MAP_FILTERS: {
        ENTIRE_WORLD: 'Entire World',
        All_REGIONS: 'All Regions',
        ALL_CITIES: 'All Cities',
        ALL_STORES: 'All Stores',
    },
    SALES_PER_METER: 'Sale Per Meter Per Day',
    SALE_PER_METER: 'SPM',
    MAP_OPENSEARCH: {
        SALES: 'Sales',
    },
    TRENDSDDISCOVERED: 'Trends Discovered',
    NO_RESULTS: 'No Results',
    Execute: 'Execute',
    Ignore: 'Ignore',
    SOME_GPS_NOTAVAILABLE: 'Some of store GPS locations not available',
    Create_AUI_Version: 'Create AUI Version',
    PLEASE_SELECT_ITEMS_FROM_SEARCH:
        'Please select items from free search to continue',
    Expand_Message: 'Give it more time!',
    Keep_Message: "This product had a good performance, Let's keep it!",
    Remove_Message: "This product had a bad performance, Let's reverse it!",
    MC: {
        BUILD: 'Build',
        IMPLEMENT: 'Implement',
        ANALYSIS: 'Analyze',
        RECOMMEND: 'Recommend',
        SETTINGS: 'Settings',
    },
    AMG: {
        TestPeriodOver: "This product is doing okay. let's wait and see.",
        NewStarIsBorn: "Wow you've got a new star.",
        InOut: 'Season is ending soon.',
        FOutOfShelf: 'Attention: Need to top up ASAP.',
        DeadWalker: 'Oops, Lets try some thing else.',
    },
    select_different: 'Select Different',
    showhidenewproducts: 'Show/Hide New Products',

    have_right_field: 'Have right field',
    have_left_field: 'Have left field',
    EDITED_USER: 'Edited User',
    SEARCH_TO_DATE: 'Search To Date',
    SEARCH_FROM_DATE: 'Search From Date',
    entermanualsyncpw: 'Enter manual sync password to continue',
    invalid_pw: 'Invalid Password, Please try again',
    theme: 'Theme',

    selectedfield_only: 'Selected field only',
    EXISTING: 'Existing',
    HISTORY: 'History',
    DATE_PERIOD: 'Date Period',

    Normal: 'Normal',
    SnapShot: 'Snap Shot',
    RELOGRAM: 'Relogram',
    DISABLED_OTHER_FIELDS:
        'This field needs to be simulated to enable this feature',
    BY_BRANCH: 'By Branch',
    Zoom_Info: 'Zoom and Pan with mouse',
    Drag_Drop_Info: 'Drag here a Category',
    DISABLED_CHECK_RESET:
        'Checks are disabled in Relogram mode. Switch to Current Version to check',
    resetZoom: 'Zoom Reset',
    mark_stackable: 'Mark Stackables',
    mark_stackable_all: 'Stackable OFF/ON All',
    SAME_OR_EXISTING_NAME: 'Same or Existing Name',
    SIMULATE_FIRST: 'Please simulate for this field count first',

    SOME_PRoD_HEIGHT_EXCEED_SELECTED_SHELF_HEIGHT:
        "Some product's height exceed selected shelf's height",
    successfilly_applied: 'Successfully applied !',
    successfullyapplied_apply_to_next_store:
        'Successfully applied. Now apply to next store',
    MISSING_NEW_PRODUCTS_AVAILABLE_IN: 'Missing new products available in',
    WHEN_PUSHING_TO_NEW_VERSION_SHOWING_BELOW:
        'when pushing to new version showing below',
    CURRENT_VERSION: 'Current Version',
    UPDTING_VERSION: 'Updating Version',
    SELECT_ALL: 'Select ALL',
    COMMAN: 'Comman',

    Product_update: 'Product Update',
    SELECT_EXCEL_FILE: 'select the excel file',
    PLEASE_SELECT_EXCEL_CORRECT_FILE: 'please select correct excel file',
    File_Size_To_Large: "you can't process more than 200000 products",
    original_value: 'original value',
    expected_value: 'expected value',
    uom_format_details: 'uom format details',
    PLEASE_SELECT_EXCEL_Block_FILE: 'please select block excel file',
    PLEASE_SELECT_EXCEL_Archive_FILE: 'please select archive excel file',
    PLEASE_SELECT_EXCEL_Product_FILE: 'please select product update excel file',
    No_Barcode: 'The product barcode does not exist',
    column_have_issue: 'column have issue',
    duplicate_barcode: 'duplicate barcode',

    UPDATE_STRATEGIES: 'Update Strategies',
    SELECT_STRATEGY_OF_SELECTEDITEM:
        'Select strategy of selected items to continue',
    ITEMS_PENDING: 'Items Pending',
    STRATEGY_DESC: 'Strategy Description',
    APPLY_TOALL: 'Apply To All',
    STRATEGY: 'Strategy',

    Apply_Products_Details: 'Apply Products Details',
    Apply_Result: 'Apply Result',
    Applied: 'Applied',
    Replace_Product_Not_Selected: 'Replace Product not selected',
    Replace_Product_Not_Available: 'Replace Product not available',
    Full: 'Full',
    Half: 'Half',
    CHAINOPTIONS: {
        A: 'Start when sales exceed 80% of connected (new product testing) branches',
        B: 'Start when sales are recorded in all connected (new product testing) branches',
        C: 'Start when the first sale appears in any connected (new product testing) branch',
        D: "Manually start the test period on Aristo's notify screen (products without a specified test start date)",
        E: 'Start when completion of all feedback tasks',
    },
    SAVE_AND_APPLY: 'Save & Apply',

    TESTPERIOD_START: 'Test Period Start',
    ARE_YOU_SURETO_CONTINUE_TESTPERIOD:
        'Are you sure to start test period of this product?',
    DateFilter: 'Date Filter',
    TESTEDSCOUNT_HASBEECHANGED_AFTERTHE_TEST_PERIOD:
        'Tested store count has been changed after the test period started.',

    SOME_STORE_NEEDS_TORESET: 'Some stores needs reset to check',
    CANNOT_CONTINUE_WITHOUT_UPDATE_DIFFSTORES:
        'Cannot continue without forcing unique display unit stores',
    CANNOT_IGNORE_WITHOUT_NONDIFFSTORES:
        'Cannot ignore all and continue without non-unique display unit stores',
    ARE_YOU_SURE_IGNOREALL_DIFFIELDS:
        'Are you sure to ignore all unique field changes and continue?',
    SURE_TO_REMOVE_THIS: 'Are you sure to remove this?',
    THIS_WILL_REMOVE_FROM_CLIPBOARD_AFTER_SAVE_PLANOGRAM_SURETO_CONTINUE:
        'This will remove from clip board after you save panogram. Are you sure to continue?',
    DROP_SHELF_NOT_AVAILABLE: 'Shelf not available for drop',
    AMG_MORE: {
        TestPeriodOver:
            "Let's expand the period and try it for a couple more days and we'll decide.",
        NewStarIsBorn:
            'Boosted sales with our new star, Keep this as your exclusive choice.',
        InOut: 'Seasonal swap ahead, this will switch back to the previous replaced product.',
        FOutOfShelf:
            "Inventory is running low on this product, stock up before it's too late!",
        DeadWalker:
            "It's time for a change, Remove this and replace it with something more profitable.",
    },
    DEPT_VIEW: 'Department View',
    TOTAL_ITEMS: 'Total Items',
    PRINT_PGDEPARTMENTS: 'Print Planogram Departments',
    PLEASE_SELECT_A_DEPTTO_CONTINUE: 'Please select a deparmtnet to continue',
    ACTIVE_PG_DETAILS_NOT_FOUND: 'Active planogram details not found',
    SHARE_PGDEPARTMENTS: 'Email Planogram Departments',
    RESETUNSAVED: 'Reset Unsaved Changes',
    SURETORESET_UNSAVED: 'Are you sure to reset unsaved changes?',
    NOPROD_SALE_AVAILABLE: 'No Product Sale Details Available',
    SNAPTABLE_LABELS: {
        MARK: 'Mark',
        PORTION: 'Portion',
        AVG: 'Avg',
        SIMSPACE: 'Sim.%',
    },
    DEPT_WARN_PRODUCT: 'Department warning product',
    RECTPER_WARN_PRODUCT: 'Recommended Warning Product',
    WARNING_TYPES: 'Warning Types',
    DEPTWARNING_HELP: 'Available in other fields',
    RECWARNING_HELP: 'Not Recommended',
    EMAIL_PRINTSHARE:
        'Planogram details were successfully shared with the given email address',
    SC_FOR_CURRENT: 'Sale Cycle is Calculated Only for Current Version',

    VMP_NOT_SAVED_SAVE_BEFORE_CONTINIUE:
        'VMP Changes not saved. Please Save Before Continue',
    Name: 'Name',
    DEPT_NOTAVAILABLE_IN_AISLE:
        'Department details not available in some departments of selected aisle',
    FIELD_NO_REQUIRED:
        'Field number required with department details required to continue',
    CHART_GENERATED_BY_SPACE: 'Chart generated by available space values',
    CONTINUE_CREATE_DRAFT: 'Continue Create Draft',
    CONTINUE_CREATE_DRAFT_MSG:
        'Are you sure to continue create draft from this active planogram?',
    DATE_PICKER: 'Date Picker',

    DEPARTMENT_LEVEL: 'Department Level',
    CATEGORY_LEVEL: 'Category Level',
    SUBCAT_LEVEL: 'Sub Category Level',

    IMPORTED_PRODLIST: 'Imported Products List',
    IMPORT_AVAILABLE_LIST: 'Import Available List',
    DELETE_IMPORTPRODS: 'Delete Imported Products',
    SURETO_DELETE_IMPORTED: 'Are you sure to delete this imported products?',
    NO_AVAILABLE_PRODS: 'No available in imported products list',
    NO_EXCELDATA_AVAILABLE: 'Empty or not matching excel data available',
    NO_PRODS_AVAILABLE_TO_DELETE: 'Products not available to delete',
    IMPORTED: 'Imported',
    DATEFILTER_DATE_RANGE_WARN: 'Date range needed for selected date filter',
    CHECK_MVPONTOP_WHEN_OVERRIDE:
        'Check MVP or On-Top when override is checked',
    CUSTOM_BRANCH_INFO:
        'In custom mode, this branch selection is used for simulation push purposes only',
    Simulation_Affected_List: 'Affected Simulation List',
    Tags_Available: 'Tags Available',
    Simulations_have_issues: 'Affected simulations by dimension edits',
    Sure_to_aknowladge_warnining: 'Are You sure to Remove ?',
    this_action__will_remove_this_warning_only_for_this_snapshot:
        'This action will remove this product warning from this snapshot',
    Hierarchy_Issue: 'Hierarchy Issue',
    Sale_Total: 'Sales Total',
    Mapping_Products: 'Mapping Products',
    PLEASE_ENTER_DEPARTMENT_NAME: 'Please enter department name',
    New_User_Password: 'New User Password',
    Character: {
        min_qty: 'minimum quantity exceeds the maximum character 15 ',
        min_rev: 'minimum revenue exceeds the maximum character 15 ',
        MVP: 'MVP exceeds the maximum character 15 ',
        results_count: 'number exceeds the maximum character 15',
        sensitivity: 'sensitivity exceeds the maximum character 15',
        width: 'width exceeds the maximum character 15',
        height: 'height exceeds the maximum character 15',
        depth: 'depth exceeds the maximum character 15',
        shelve_height: 'shelve height exceeds the maximum character 15',
        shelve_gap: 'shelve gap exceeds the maximum character 15',
        shelve_count: 'needed shelve exceeds the maximum character 15',
        length: 'length exceeds the maximum character 15',
        Breadth: 'Breadth exceeds the maximum character 15',
        max_qty: 'maximum quantity exceeds the maximum character 15 ',
        max_rev: 'maximum revenue exceeds the maximum character 15 ',
        search_text: 'search text exceeds the maximum character 255 ',
        results: 'results count exceeds the maximum character 15 ',
        code: 'code exceeds the maximum character 255 ',
        name: 'name exceeds the maximum character 255 ',
        address: 'address exceeds the maximum character 255 ',
        location: 'location exceeds the maximum character 255 ',
        city: 'city exceeds the maximum character 255 ',
        telephone: 'telephone exceeds the maximum character 255 ',
        email: 'email exceeds the maximum character 320 ',
        suggestprodviewlevel:
            'suggested product view level exceeds the maximum character 15',
        salesimportbcode:
            'sales import branch code exceeds the maximum character 255',
        removeQuantity: 'remove quantity exceeds the maximum character 15',
        first_name: 'first name exceeds the maximum character 255',
        lastName: 'last name exceeds the maximum character 255',
        password: 'password exceeds the maximum character 255',
        groupname: 'group name exceeds the maximum character 255',
        barcode: 'product barcode exceeds the maximum character 255',
        filename: 'file name exceeds the maximum character 255',
        depname: 'department name exceeds the maximum character 255',
        StackableCount: 'stackable Count exceeds the maximum character 15',
        sub_Cat: 'sub category name exceeds the maximum character 255',
        Cat: 'category name exceeds the maximum character 255',
        tagName: 'tag name exceeds the maximum character 255',
        brandName: 'brand name exceeds the maximum character 255',
        supplierName: 'supplier name exceeds the maximum character 255',
        supplierCode: 'supplier code exceeds the maximum character 255',
        unitName: 'unit name exceeds the maximum character 255',
        floorName: 'floor name exceeds the maximum character 255',
        TaskName: 'task name exceeds the maximum character 255',
        description: 'description exceeds the maximum character 255',
        ProductName: 'product name exceeds the maximum character 255',
        ProductBarocde: 'product barcode exceeds the maximum character 255',
        PaceofSale: 'Pace of Sale exceeds the maximum character 15',
        ShelveLife: 'Shelve Life exceeds the maximum character 15',
        questionnaire_name:
            'questionnaire name exceeds the maximum character 255',
        question_name: 'question name exceeds the maximum character 255',
        FIELD_COUNT: 'field count exceeds the maximum character 15',
        min_width: 'minimum width exceeds the maximum character 15',
        max_width: 'maximum width exceeds the maximum character 15',
        min_height: 'minimum height exceeds the maximum character 15',
        max_height: 'maximum height exceeds the maximum character 15',
        max_depth: 'maximum depth exceeds the maximum character 15',
        min_depth: 'minimum depth exceeds the maximum character 15',
        saleDateCount: 'sale date count exceeds the maximum character 15',
        option_text: 'option text exceeds the maximum character 255',
        Comment_text: 'Comment text exceeds the maximum character 255',
        filter_name: 'filter name text exceeds the maximum character 255',
    },
    validation: {
        min_qty: 'minimum quantity field is required',
        mvp_percentage: 'MVP percentage field is required',
        min_revenue: 'minimum revenue field is required',
        area: 'Area is required ',
        code: 'Code is required',
        name: 'Name is required',
        address: 'Address is required',
        location: 'Location is required',
        city: 'City is required',
        Product_view_level: 'Product view level is required',
        Sales_import_branch: 'Sales import branch code is required',
        Product_remove_quantity: 'Product remove quantity is required',
        shelve_count: 'Exceed the maximum number',
        NumberInputValidation: 'Exceed the maximum number',
    },
    DELETE_HEIRARCHY: 'Delete hierarchy',
    SURTO_DELETE_HEIRARCHY: 'Are you sure to delete this hierarchy?',
    ONCE_DATE_REQ: 'Once date required !',
    ADD_PRODUCT_WIDTH: 'Add product width',
    ADD_PRODUCT_HEIGHT: 'Add product height',
    ADD_PRODUCT_DEPTH: 'Add product depth',
    version_no: 'Version no text exceeds the maximum character 255',
    PLEASE_SET_VALID_Depth_RANGE: 'Please set a valid depth range',
    PLEASE_SET_VALID_height_RANGE: 'Please set a valid height range',
    PLEASE_SET_VALID_width_RANGE: 'Please set a valid width range',
    SIMULATOR_NOT_AVAILABLE: 'Simulator Not Available',
    SIMULATOR: 'Simulator',
    UnabletoDropProductbecuse: 'Unable to drop product because',
    Product_is_Archived: 'Product is archived',
    Product_is_NewProduct:
        'Product is a new product, mark it as an old product',
    product_Didnt_sendto_dep: 'Send to department & mark as old product',
    Mark_as_old: 'Mark as old',
    SURE_TO_MARK_AS_OLD: 'Are you sure to mark this product as old?',
    SIM_PRINT_PDF: 'Simulation PDF print',
    SIM_PRINT_PDF_FIELDWISE: 'Simulation PDF print - field wise',
    SIM_PRINT_EXCEL: 'Simulation excel print',
    SIM_PRINT_EXCEL_FIELDWISE: 'Simulation excel print - field wise',
    EXCEL_UPLOAD_MAXIMUM: 'Excel Product Import',
    EXCEL_UPLOAD_MAXIMUM_CONFIRM:
        'Maximum barcodes count exceded (5000). It will take some time to update product details. Are you sure to continue?',
    EXCELDATA_UPLOADLIMIT_WARN: 'Cannot upload more than 10,000 barcodes',
    Disconnect: 'Disconnect',
    EXPAND_DAYS_COUNT: 'Expand Days Count',
    NO_SALES_DATA: 'No Sales Data',
    VMP_VERSION_LIST_SELECTED:
        'VMP Versions not selected, Plase select VMP versions and research again',
    SELECTED_VER_NO_PRODUCTS:
        'Selected VMP versions do not have any replace products',
    SPM_MESSAGE: "SPM is calculated based on department's latest active layout",
    SALECYCLE_MESSAGE: 'Sale cycle is calculated in chain level',
    BASED_VERSION: 'Based Version',

    this_action__will_remove_this_all_warning_only_for_this_snapshot:
        'This action will clear all edited product warning from this snapshot',
    Data_updated: 'Dimension Updated',
    DIMENSION_CHNGES_NOT_AFFECTED: 'Dimension changes are not affected',

    PRODUCT_IS_MISSING_DETAILS: 'Product dimensions missing',
    PRODUCT_IS_MISSING_DETAILS_BRAND: 'Product dimensions, brand missing',
    PRODUCT_IS_MISSING_DETAILS_BRAND_IMAGE:
        'Product dimensions, brand, image missing',
    PRODUCT_IS_MISSING_DETAILS_BRAND_HEIRARCHY:
        'Product brand, hierarchy missing',
    PRODUCT_IS_MISSING_DETAILS_IMAGE: 'Product dimensions, image missing',
    PRODUCT_IS_MISSING_DETAILS_HEIRARCHY:
        'Product dimensions, hierarchy missing',
    PRODUCT_IS_MISSING_BRAND: 'Product brand missing',
    PRODUCT_IS_MISSING_BRAND_IMAGE: 'Product brand, image missing',
    PRODUCT_IS_MISSING_BRAND_IMAGE_HEIRARCHY:
        'Product brand, image, hierarchy missing',
    PRODUCT_IS_MISSING_BRAND_HEIRARCHY: 'Product brand, hierarchy missing',
    PRODUCT_IS_MISSING_IMAGE: 'Product image missing',
    PRODUCT_IS_MISSING_IMAGE_HEIRARCHY: 'Product image, hierarchy missing',
    PRODUCT_IS_MISSING_HEIRARCHY: 'Product hierarchy missing',
    PRODUCT_IS_MISSING_DETAILS_BRAND_IMAGE_HEIRARCHY:
        'Product dimensions, brand, image, hierarchy missing',
    FIELD_WISE_PRINT: 'Field Wise Print',
    CATEGORY_WISE_PRINT: 'Category Wise Print',

    Excel_Export: {
        PRODUCT_BARCODE: 'Product Barcode',
        PRODUCT_NAME: 'Product Name',
        hierarchyCompleteStatus: 'Hierarchy Complete Status',
        Shelve_No: 'Shelve No.',
        Field_No: 'Field No.',
        planigo_prodinfo_export: 'planigo product info export',
        product_details: 'product_details',
        Filtered_products: 'Filtered products',
        All_products: 'Products apeared in active planograms',
    },
    soldQty: 'Sold Qty',
    NumberInputValidation: 'Exceed the maximum number',
    block_unitl_date: 'Block until date',
    mvp_override: 'MVP override',
    ISMVP_OR_ONTOP: 'isMVP / isOntop',
    usageStatus: 'Usage status',
    CANNOT_CHANGE_OVERSAFTY_MORETHAN_FIELDHEIGHT:
        'Cannot change overlap safty margin more than field height',
    OVERLAP_PROD_AVAILABLE_REMOVE_TO_EDIT_SAFTY:
        'Overlap products available, Remove them to edit overlap safty margin',
    Product_department_not_matching:
        'Product department is not matching with simulation department',

    RESET_AND_CURRENT: 'Reset and View Current Version',
    VIEW_RELOGRAM: 'View Relogram',
    ARE_YOU_SURE_YOU_WANT_TO_RESET_AND_VIEW_THE_CURRENT_SIM:
        'Are you sure you want to Reset and view the Current Version?',
    ARE_YOU_SURE_YOU_WANT_TO_VIEW_THE_RELOGRAM:
        'Are you sure you want to view the Relogram?',
    REVERTING_TO_RELOGRAM: 'Reverting to relogram',
    RESETTING_TO_CURRENT_VERSION: 'Resetting to planogram',
    RESET_TO_CURRENT_VERSION: 'Reset to current version',
    THIS_STORE_NEEDS_TORESET:
        'This store needs to be reset to the current version.',
    DO_YOU_WANTS_TO_CONTINUE: 'Do you want to continue?',

    DO_YOU_WANT_TO_SAVE_AND_PRINT:
        'Your last changes are not saved. Do you want to print this after complete save?',
    SIMULATION_OF: 'Simulation of',
    SIMULATION: 'Simulation',
    SIMULATIONS: 'Simulations',
    STACK_COUNT: 'Stack Count',
    Job_Status: 'Job Status',
    Canceled: 'Canceled',
    Catelogue: 'Catelogue',
    Sale: 'Sale',
    VERIFY_TITLE: "Oops, you don't have access to this page",
    VERIFY_BODY_TEXT: 'If you want to access this page, verify your ownership.',
    VERIFY_Btn: 'VERIFY YOUR OWNERSHIP',
    CONFIRM_QUEUE: 'Confirm queue job tab unlock',
    CONFIRM_QUEUE_L: 'Enter queue job tab unlock password to continue',
    password_limmit: 'password exceeds the maximum character 255',
    DATE_VALIDATION_MSG: 'From date must be earlier than or equal to To date',
    FROM_DATE_REQUIRED: 'From date required',
    TO_DATE_REQUIRED: 'To date required',
    Job_ID: 'Job ID',
    Queue_Jobs: 'Queue Jobs Log',
    EXCECUTED_SUCCESSFULLY: 'Executed Successfully!',
    New_password_details_successfully_saved:
        'New password details successfully saved',
    COULD_NOT_THE_LOAD_IMAGE: 'Could not load the image',
    SUCCESSFULLY_BRANCH_DATA: 'Successfully branch data ',
    ERROR_OCCORED_ON: 'Error occurred on ',
    _process: ' proces',
    successfully_branch_details_deleted: 'Successfully branch details deleted',
    ERROR_OCCOURED_IN_DELETE_PROCESS: 'Error occurred in delete proces',
    FROM_DATE_SHOULD_BE_PRIOR_TO_END_DATE:
        'From date should be prior to End date',
    REFERENCE_NOT_AVAILABLE: 'Reference not available',
    SUCCESSFULLY_APPROVED: 'Successfully Approved!',
    SUCCESSFULLY_SYNCED: 'Successfully Synced!',
    ENTER_VALID_DATES: 'Enter Valid Search Dates',
    SUCCESSFULLY_RESOLVED: 'Successfully marked as Resolved!',
    SUCCESSFULLY_UNRESOLVED: 'Successfully marked as Unresolved!',
    Confirm_to_delete_a_region: 'Confirm to delete a region',
    ARE_YOU_SURE_TO_DELETE_THIS_REGION: 'Are you sure to delete this region?',

    CONFIRM_TO_CHANGE_REFIONAL_MANAGER: 'Confirm to change region manager',
    CHANGE_REGIONAL_MANAGER_MESSAGE:
        'This user can be used in other regions, Are you sure to change this user as region manager?',
    SUCCESSFULLY_REGIONAL_DATA_SAVED: 'Successfully region data saved',
    SUCCESSFULLY_REGIONAL_DATA_UPDATED: 'Successfully region data updated',
    SUCCESSFULLY_REGIONAL_DATA_DELETED: 'Successfully region data deleted',

    country_required: 'Country is required',
    code_required: 'Code is required',
    entervalidcode_length: "Code is can't be less than 3 letters",
    entervalidname_length: "Name is can't be less than 3 letters",

    DELETE_USER_DETAILS: 'Successfully user details deleted',
    SAVE_USER_DETAILS: 'Successfully user details saved',
    UPDATE_USER_DETAILS: 'Successfully user details updated',
    DELETE_USERGROUP_DETAILS: 'Successfully user group deleted',
    SAVE_USERGROUP_DETAILS: 'Successfully user group saved',
    UPDATE_USERGROUP_DETAILS: 'Successfully user group updated',

    SENSITIVITY_STATUS: { empty: 'Empty', half: 'Half', full: 'Full' },
    SELECT_ACTUAL_STORE_STATE: 'Select an actual store state',
    ADD_SENSITIVE_VALUE: 'Add Sensitive Value',
    INVALID_SENSITIVE_VALUE: 'Invalid Sensitive Value',

    SAVED_DEPARTMENT_SUCCESSFULLY: 'Successfully department saved',
    UPDATED_DEPARTMENT_SUCCESSFULLY: 'Successfully department updated',
    CONFIRM_TO_DELETE: 'Confirm to delete',
    CONFIRM_TO_DELETE_MSG: 'Are you sure to continue delete this item?',
    CONFIRM_TO_DELETE_DUNIT: 'Confirm to Delete Display Unit',
    CONFIRM_TO_DELETE_DUNIT_MSG: 'Are you sure to remove this Display Unit?',
    DELETED: 'Deleted',
    CONFIRM_TO_DELETE_FLOOR: 'Confirm to Delete Floor',
    CONFIRM_TO_DELETE_FLOOR_MSG: 'Are you sure to remove this Floor?',

    Profit_Weight_Validation: 'Profit weight exceeds the maximum character 15',
    Sold_Qty_Validation: 'Sold qty exceeds the maximum character 15',
    Sale_Weight_Validation: 'Sale weight exceeds the maximum character 15',
    MVP_Validation: 'MVP exceeds the maximum character 15',
    Min_Revenue_Validation: 'Min revenue exceeds the maximum character 15',
    min_qty_Validation: 'Min qty exceeds the maximum character 15',
    min_qty_require: 'minimum quantity field is required',
    mvp_percentage_require: 'MVP percentage field is required',
    min_revenue_require: 'minimum revenue field is required',
    sale_weight_percentage_require: 'sale weight percentage field is required',
    profit_weight_percentage_require:
        'profit weight percentage field is required',
    sold_qty_weight_percentage_require: 'sold qty weight field is required',
    WEIGHT: 'Weight',
    TOTAL_WEIGHT_CANNOT_BE_MORETHAN_100:
        'Total weight percentage cannot be more than 100%',

    Converted_simulations_group_was_not_found_in_the_AUI:
        "Converted simulation's group was not found in the AUI.",

    archived: 'Archived',
    ELIGIBLE_STATUS: {
        eligible: 'Eligible',
        ineligible: 'Not Eligible',
    },
    THIS_VMP_NEEDS_TOREFRESH: 'This VMP needs to refresh',
    RESOLVED_PRODLIST: 'Resolved Product List',
    REFRESH_VMP: 'Refresh Master Planogram',
    SURETO_REFRESHVMP: 'Are you sure to refresh master planogram?',
    SEND_TO_DEP_AND_REFRESH: 'Send to Department & Refresh',
    NEW_PROD_SENDTODEP_CONFIRM_REFRESH:
        'Are you sure to send this product to master planogram and refresh master planogram?',
    DELETED: 'Deleted',
    VMP_SUCCESSFULLY_REFRESHED: 'Master planogram successfully refreshed',
    mark_product_as_send_to_dep: 'Product sent to department',
    data_missing: 'Data missing',
    ACTIONS: 'Actions',
    REFRESH: 'Refresh',
    USE_MINREV_IN_PRODLEVEL: 'Use min. revenue in Product Level',
    removed: 'Removed',
    MANDATORY: 'Mandatory',
    ELIGIBILITY_BASED_ON: 'Eligibility based on,',
    IF_PRODS_MISSING_IN_RESOLVE:
        'If some products are missing, they will appear in the issue panel.',
    MANUAL_FIXED_CANNOT_RESOLVED:
        'The manual-fixed products cannot be resolved here.',
    INOUT_STATUS: {
        inout: 'In/Out',
        in: 'IN',
        out: 'OUT',
    },
    ONLY_USAGESTATUS_NONE_PRODS:
        'Products shown in this modal are those that have no usage and can be sent to departments.',
    PRODUCT_DETAILS_NOT_FOUND: 'Product details not found',
    SUCCESSFULLY_SAVED: 'Successfully saved!',
    PRODUCTS_NOT_LOADED: 'Products Not Loaded !',
    CANNOT_LOAD_PLANOGRAM_FLOOR_DETAILS: 'Cannot load planogram floor details',
    INVALID_USERNAME_OR_PASSWORD: 'Invalid username or password',

    // transaltion codes from BE
    INV_MP_ID: 'Invalid Master Planogram Id!',
    MP_CAT_NOT_FOUND: 'Mp_Has_Category Not Found!',
    AISL_ALLOC_SLOT_CAT_NOT_FOUND:
        'Aisle Allocation Slot Assigned Categories Not Found!',
    AISL_ALLOC_SLOT_NOT_FOUND: 'Aisle Allocation Slot Not Found!',
    AISL_ALLOC_SLOT_ID_NOT_FOUND: 'Aisle Allocation Slot Id Not Found!',
    AISL_LST_NOT_FOUND: 'Aisles List Not Found!',
    AISL_ALLOC_NOT_FOUND: 'Aisle Allocation Not Found!',
    REQ_DT_MISS_CHK_AVAIL: 'Required Data Missing to Check Availability',
    INV_COMPL_STAT: 'Invalid Compliance Status!',
    DT_RNG_NOT_FOUND: 'Date Range Not Found!',
    MP_NOT_FOUND: 'Master Planogram Not Found!',
    SYS_USER_NOT_AVAIL_TASK_CANNOT_CONT:
        'System User Is Not Available. Current Task Cannot Continue Without System User.',
    INV_DEPT_ID: 'Invalid Department Id!',
    INV_CAT_ID: 'Invalid Category Id!',
    DUPL_NAME_AVAIL: 'Duplicate Name Available!',
    EMP_NAME: 'Empty Name!',
    ERR_WHILE_UPDT_DEPT_MD:
        'Error Occurred While Updating Department Metadata.',
    INCORR_PW: 'Incorrect Password',
    ENT_PW_TO_DEL: 'Enter Password to Delete',
    NO_DATA_AVAIL_FOR_IMPL: 'No Data Available for Implement!',
    WRNG_FD_CT_SEL: 'Wrong Field Count Selected!',
    MNY_CONF_LYTS: 'There Have Many Confirm Layouts',
    INV_OVERLAP_SLF_INFO: 'Invalid Overlapping Shelf Information!',
    NO_AC_RES: "You Don't Have Access to the Resource!",
    FD_DRW_OUT_OF_BOX: 'Field will Draw Out of the Box!',
    PLG_SHLD_CONF_FIRST: 'Planogram Should Confirm First!',
    ONG_MP_PLG_IMPL_JOB_WAIT:
        'Ongoing Master Planogram Implement Job is Happening, Please Wait for a Few Minutes!',
    PROD_NAME_NOT_FOUND: 'Product Name Not Found',
    BARC_EXISTS: 'Barcode is Already Exists',
    NO_IMG_FOUND: 'No Image Found!',
    MAND_PROD_NOT_ONTOP: "Mandatory Product Couldn't Be OnTop Product",
    PROD_REQ_MVP_ONTOP: "Product Couldn't Exist Without MVP or OnTop",
    MAND_PROD_REQ_MVP: "Mandatory Product Couldn't Be Exist Without MVP Flag",
    DEPT_CAT_HIER_MISMATCH: 'Department and Category Hierarchy Mismatch!',
    CAT_SUBCAT_HIER_MISMATCH: 'Category and Subcategory Hierarchy Mismatch!',
    NO_PROD_HIER_FIX: 'No Products Selected for Hierarchy Fix!',
    INV_DATA: 'Invalid Data',
    NO_PROD_STACK_MARK: 'No Products to Mark Stackable!',
    STR_INFO_NOT_FOUND: 'Cannot Find Store Information',
    ERR_SAVE_PROD: 'Error Occurred While Saving Products',
    ERR_SAVE_CAT_STAT_LOG: 'Error Occurred While Saving Catalog Status Log',
    ERR_SAVE_HIER_ISSUES: 'Error Occurred While Saving Hierarchy Issues',
    ERR_SAVE_ISSUE_PROD: 'Error Occurred While Saving Issue Product',
    ERR_VAL_HIER_IMP_CATALOG:
        'Error Occurred While Validate Hierarchy Data While Import Catalog Data',
    ERR_SAVE_MSG_Q_JOB:
        'Error Occurred While Save New Message Queue Job Details',
    ERR_SAVE_CAT_IMP_STAT_PROD:
        'Error Occurred While Saving New Catelog Import Status Log - Product',
    ERR_SAVE_NEW_PROD: 'Error Occurred While Saving New Product',
    ERR_SAVE_CAT_IMP_STAT_DEPT:
        'Error Occurred While Saving New Catelog Import Status Log - Department',
    ERR_SAVE_NEW_DEPT: 'Error Occurred While Saving New Department',
    ERR_SAVE_NEW_DEPT_APPR:
        'Error Occurred While Saving New Department for Approval',
    ERR_SAVE_CAT_IMP_STAT_CAT:
        'Error Occurred While Saving New Catalog Import Status Log - Category',
    ERR_SAVE_NEW_CAT: 'Error Occurred While Saving New Category',
    ERR_SAVE_NEW_CAT_APPR:
        'Error Occurred While Saving New Category for Approval',
    ERR_SAVE_CAT_IMP_STAT_SUBCAT:
        'Error Occurred While Saving New Catalog Import Status Log - Subcategory',
    ERR_SAVE_NEW_SUBCAT: 'Error Occurred While Saving New Subcategory',
    ERR_SAVE_NEW_SUBCAT_APPR:
        'Error Occurred While Saving New Subcategory for Approval',
    ERR_SAVE_CAT_IMP_STAT_SUPP:
        'Error Occurred While Saving New Catalog Import Status Log - Supplier',
    ERR_SAVE_NEW_SUPP: 'Error Occurred While Saving New Supplier',
    ERR_SAVE_NEW_SUPP_APPR:
        'Error Occurred While Saving New Supplier for Approval',
    ERR_SAVE_CAT_IMP_STAT_BRAND:
        'Error Occurred While Saving New Catalog Import Status Log - Brand',
    ERR_SAVE_NEW_BRAND: 'Error Occurred While Saving New Brand.',
    ERR_SAVE_ERR_LOG: 'Error Occurred While Saving Error Log',
    ERR_UPD_IMP_REF_CODE_DEPT:
        'Error Occurred While Updating Import Reference Code of Department',
    ERR_UPD_IMP_REF_CODE_CAT:
        'Error Occurred While Updating Import Reference Code of Category',
    NO_SUBCAT_APPR_WO_VAL_CAT:
        'You Cannot Approve Subcategory Without Valid Category.',
    ERR_UPD_IMP_REF_CODE_SUBCAT:
        'Error Occurred While Updating Import Reference Code of Subcategory',
    ERR_APPRV_SUBCAT_DATA: 'Error Occurred While Approving Subcategory Data',
    ERR_UPD_CAT_STAT_LOG_STAT:
        'Error Occurred While Updating Status of Catalog Status Log.',
    VAL_SUBCAT_APPR_NOT_FOUND:
        'Valid Subcategory Data Approval Cannot Be Found.',
    INV_SUPP_DATA_CHK_AGAIN:
        'Invalid Supplier Data Available. Please Check Again.',
    ERR_UPD_IMP_REF_CODE_SUPP:
        'Error Occurred While Updating Import Reference Code of Supplier',
    VAL_SUPP_NOT_FOUND: 'Valid Supplier Cannot Be Found',
    ERR_APPRV_SUPP_DATA: 'Error Occurred While Approving Supplier Data',
    VAL_SUPP_DATA_APPR_NOT_FOUND:
        'Valid Supplier Data Approval Cannot Be Found.',
    VAL_CAT_NOT_FOUND: 'Valid Category Cannot Be Found.',
    INV_BRAND_DATA_CHK_AGAIN:
        'Invalid Brand Data Available. Please Check Again.',
    ERR_UPD_IMP_REF_CODE_BRAND:
        'Error Occurred While Updating Import Reference Code of Brand',
    ERR_FIND_EXIST_BRAND: 'Error Occurred While Finding Existing Brand',
    ERR_APPRV_BRAND_DATA: 'Error Occurred While Approving Brand Data',
    VAL_BRAND_DATA_APPR_NOT_FOUND: 'Valid Brand Data Approval Cannot Be Found.',
    VAL_FILES_NOT_FOUND_IMP_TRY_AGAIN:
        'Valid Files Cannot Be Found To Import. Please Try Again.',
    ERR_UPD_ORIG_REFS: 'Error Occurred While Update Original References',
    AI_IMG_IDS_INV: 'AI Image Ids Invalid!',
    INV_FIELD: 'Invalid Field!',
    CHAIN_NOT_FOUND: 'Cannot Find the Chain',
    INV_COMPL_ID: 'Invalid Compliance Id!',
    INV_COMPL: 'Invalid Compliance!',
    INV_COMPL_IMG_ID: 'Invalid Compliance Image Id!',
    INV_COMPL_IMG: 'Invalid Compliance Image!',
    NO_CHAIN_BY_UUID: 'Cannot Find Chain By Chain Uuid',
    SUPP_NAME_EXISTS: 'Supplier Name Already Exists.',
    VAL_BRAND_UPD_NAME_CHK_AGAIN:
        'Valid Brand Available With Updated Name. Please Check Again.',
    ERR_UPD_EXISTING_BRAND: 'Error Occurred While Updating Existing Brand',
    ERR_SAVE_BRAND_CONFLICT_LOG:
        'Error Occurred While Saving Brand Conflict Log.',
    INV_DATA_CHK_AGAIN: 'Invalid Data Available. Please Check Again.',
    CANT_DELETE_REF: 'Cannot Delete Reference',
    CAT_NAME_EXISTS: 'Category Name Already Exists. [$]',
    SUBCAT_NAME_EXISTS: 'Subcategory Name Already Exists. [$]',
    ITEM_REFS_EXIST: 'Item References Available.',
    CANNOT_DEL_ALL_CAT_SUBCAT_REFS_EXIST:
        'You Cannot Delete All Categories. Subcategory References Available for Some Categories.',
    CAT_NAME_COLOR_EXISTS: 'Category Name and Color Already Exists. [$], [$]',
    DEPT_NAME_EXISTS: 'Department Name Already Exists.',
    VAL_CAT_UPD_NAME_CHK_AGAIN:
        'Valid Category is Available for the Updated Name. Please Check Again.',
    ERR_UPD_EXISTING_CAT: 'Error Occurred While Updating Existing Category',
    ERR_SAVE_CAT_CONFLICT_LOG:
        'Error Occurred While Saving Category Conflict Log.',
    VAL_SUBCAT_UPD_NAME_CHK_AGAIN:
        'Valid Subcategory Available With Updated Name. Please Check Again.',
    ERR_UPD_EXISTING_SUBCAT:
        'Error Occurred While Updating Existing Subcategory',
    ERR_SAVE_SUBCAT_CONFLICT_LOG:
        'Error Occurred While Saving Subcategory Conflict Log.',
    SOMETHING_WRONG: 'Something Went Wrong',
    ERR_SAVE_DEPT_CONFLICT_LOG:
        'Error Occurred While Saving Department Conflict Log.',
    SUPP_CODE_EXISTS: 'Supplier Code Already Exists.',
    ERR_EDIT_SELECTED_SUPP:
        'Error Occurred While Editing the Selected Supplier',
    SIM_SNAP_CPY_FAIL: 'Simulation snapshot Copy Save Unsuccessful',
    SIM_SNAP_ENT_NOT_FOUND: 'Simulation snapshot Entity Not Found',
    SIM_SNAP_MISSING: 'Simulation snapshotid Missing',
    NO_REC_IN_CON_DISCON: 'No Records In Connect Disconnect Job',
    INV_USER: 'Invalid User!',
    ERR_UPD_PROD_MVP_ONTOP:
        'Error Occurred While Updating Product Mvp and OnTop.',
    INV_BRAND: 'Invalid Brand!',
    BRAND_RULE_NOT_EMPTY: 'Brand and Rule Both Cannot Be Empty!',
    DEPT_HAS_ALREADY_CREATED_MP:
        'This Department Has Already Created a Master Planogram!',
    SOMETHING_WRONG_MP_SAVE: 'Something went wrong with Master Planogram save!',
    INV_RANK: 'Invalid Rank!',
    INV_DISP_UNIT_SEL: 'Invalid Display Unit Selection!',
    MP_FIELD_SAVE_ERR: 'Master Planogram Field Save Error!',
    CAT_RULE_NOT_EMPTY: 'Category and Rule Both Cannot Be Empty!',
    MP_CAT_NOT_SAVED: 'Mp Category Not Saved!',
    DEPT_NOT_FOUND: 'Cannot Find the Department',
    VAL_DEPT_META_NOT_FOUND: 'Valid Department Metadata Cannot Be Found.',
    ERR_UPD_DEPT_META: 'Error Occurred While Updating Department Metadata.',
    PROD_NOT_FOUND: 'Cannot Find the Product',
    INV_SUBCAT: 'Invalid Subcategory!',
    SUBCAT_RULE_NOT_EMPTY: 'Subcategory and Rule Both Cannot Be Empty!',
    SRV_SIDE_EXC: 'Server Side Exception',
    PROD_ID_NOT_FOUND: 'Cannot Find Product Id',
    INV_SIM: 'Invalid Simulation!',
    UPD_CONN_DISCONN_JOB_FAIL: 'Updating Connect Disconnect Job Record Failed',
    TKBK_FAIL: 'Takeback Failed',
    SIM_SNAP_SAVE_ISSUE: 'Simulation Snapshot Save Issue!',
    WRNG_DEF_SIM_PROD_UPD: 'Wrong Definition With Simulation Product Update!',
    SNAP_ID_NOT_FOUND: 'Snapshot Id Not Found!',
    NO_DUP_SIM_CLIP_SAVE: "Can't Save Duplicate Simulation Clipboard Data!",
    NOTIF_TMPL_NOT_FOUND: 'Notification Template Not Found.',
    UPD_FAIL_SHELF_CHG_LOG: 'Update Failed On Shelf Change Log!',
    FLD_LEFT_MAP_ERR: 'Field Left Not Mapped Correctly!',
    FLD_LEFT_MAP_ERR_NO_REL:
        'Field Left Not Mapped Correctly , Cannot Find Relationship!',
    LFT_FLD_DEL_FAIL_INV_ID:
        'Left Side Field Cannot Be Deleted. Not Provide Valid Id!',
    FLD_RIGHT_MAP_ERR: 'Field Right Not Mapped Correctly!',
    FLD_RIGHT_MAP_ERR_NO_REL:
        'Field Right Not Mapped Correctly, Cannot Find Relationship!',
    RGT_FLD_DEL_FAIL_INV_ID:
        'Right Side Field Cannot Be Deleted. Not Provide Valid Id!',
    FLR_LAYOUT_NOT_FOUND: 'Cannot Find the FloorLayout',
    TASK_MS_COMM_FAIL: 'Cannot Communicate With Task Micro Service [$]',
    ERR_SAVE_SUPP_GS1_IMP:
        'Error Occurred While Saving New Supplier When Import Product from GS1.',
    ERR_SAVE_BRAND_GS1_IMP:
        'Error Occurred While Saving New Brand When Import Product from GS1',
    NEW_PROD_META_UUID_NOT_FOUND: 'Cannot Find New Product Meta Uuid',
    NO_REPL_PROD_FOR_VAL: 'No Replace Product for [$]',
    INV_SEL_DATE_RANGE: 'Invalid Selected Date Range',
    NEW_PROD_NOT_FOUND: 'Cannot Find New Product',
    CANT_CANCEL_ONGOING_NEW_PROD: 'Cannot Cancel Ongoing New Product',
    NEW_PROD_ID_NOT_FOUND: 'Cannot Find New Product Id',
    CANT_CANCEL_PROD_NOT_NEW_TEST:
        'Cannot Cancel the Product. The New Product Not a New Testing',
    FLR_LAYOUT_BY_VER_NOT_FOUND:
        'Cannot Find FloorLayout by FloorLayout Version',
    FLR_LAYOUT_BY_UUID_NOT_FOUND: 'Cannot Find FloorLayout by FloorLayout Uuid',
    STORE_BY_UUID_NOT_FOUND: 'Cannot Find Store by Store Uuid',
    CHAIN_NOT_FOUND_FOR_STORE: 'Cannot Find Chain for the [$] Branch',
    NEW_PROD_DEPT_NOT_FOUND: 'Cannot Find New Product Department',
    NEW_PROD_DEPT_ID_NOT_FOUND: 'Cannot Find New Product Department Id',
    CANT_SAVE_NEW_PROD_TYPE_GRP_SGRP:
        'Cannot Save New Product. There Are Many New Products. The Type Should Be Group or Separategroup',
    CANT_SAVE_1_NEW_PROD_TYPE_NORM:
        'Cannot Save New Product. There Is One New Product. The Type Should Be Normal',
    TYPE_NORM_GRP_SGRP_NOT_FOUND:
        'Cannot Find the Type (Normal/Group/Separategroup)',
    NEW_PROD_BRAND_NOT_FOUND: 'Cannot Find New Product Brand',
    NEW_PROD_BRAND_ID_NOT_FOUND: 'Cannot Find New Product Brand Id',
    INV_FACING_VAL: 'Invalid Facing Value',
    REPL_PROD_NOT_FOUND: 'Cannot Find Replace Product',
    REPL_PROD_ID_NOT_FOUND: 'Cannot Find Replace Product Id',
    STRAT_NOT_FOUND_LIN_REPL_MAX_MIN_ATT:
        'Cannot Find Strategy (Linear/Replace/Max Face/Min Sales/Max Face With Min Sales/Attached)',
    SEL_OPT_NOT_FOUND_ALL_USED_SEL:
        'Cannot Find Selection Option (All/Used/Selected)',
    TEST_PERIOD_GT_0: 'The Test Period Should Not Less Than or Equal 0',
    FACING_CNT_GT_0: 'The Facing Count Should Not Less Than or Equal 0',
    FACING_NOT_FOUND: 'Cannot Find Facing',
    VMP_VER_NOT_FOUND: 'Cannot Find VMP Version',
    VMP_VER_LIST_NOT_FOUND: 'Cannot Find VMP Version List',
    NOTHING_TO_SAVE: 'There Is Nothing to Save',
    ERR_SAVE_NEW_PROD_BRAND_LOG:
        'Error Occurred While Save New Product Brand Change Log.',
    BRAND_NAME_EXISTS: 'Brand Name Already Exists.',
    ERR_UPD_EXIST_PROD: 'Error Occurred While Updating Existing Product',
    ERR_SAVE_PROD_CONFLICT_LOG:
        'Error Occurred While Saving Product Conflict Log.',
    CANT_DEL_PROD_ACT_USAGES:
        'You Cannot Delete This Product When Active Usages Are Available',
    ERR_DEL_PROD_FROM_SNAP:
        'Error Occurred While Deleting Product from Snapshot.',
    ERR_DEL_PROD: 'Error Occurred While Deleting Product.',
    CANT_DEL_PROD: 'You Cannot Delete This Product',
    NEW_PROD_META_ID_NOT_FOUND: 'Cannot Find New Product Meta Id',
    STRAT_LEVEL_NOT_FOUND: 'Cannot Find Strategy Level',
    CATS_NOT_FOUND: 'Cannot Find Categories',
    DEPTS_NOT_FOUND: 'Cannot Find the Departments',
    SUBCATS_NOT_FOUND: 'Cannot Find Subcategories',
    BRANDS_NOT_FOUND: 'Cannot Find Brands',
    ERR_ARCH_PROD: 'Error Occurred While Archiving Product.',
    ERR_GS1_INV_BARCODE:
        'Cannot Read GS1 Information Because of the Invalid Barcode! (Barcode: [$])',
    CANT_OVERRIDE_BRAND_GS1_VMP_REL:
        'Cannot Override Existing Brand With GS1 Brand Because of the VMP Relationships',
    CANT_OVERRIDE_BRAND_GS1_EMPTY:
        'Cannot Override Brand Because of the GS1 Brand Is Empty',
    CUR_VER_NOT_AVAIL: 'Current Version Not Available',
    UPD_STORE_NOT_AVAIL: 'Updating Store Not Available',
    CUR_MP_NOT_FOUND: 'Current MP Not Found',
    UPD_MP_NOT_FOUND: 'Updating MP Not Found',
    PROD_ALREADY_EXISTS: 'Product Is Already Exists',
    ERR_SAVE_NEW_NOOS_PROD: 'Error Occurred While Saving New Noos Product',
    ERR_SAVE_NEW_STORE_PROD: 'Error Occurred While Saving New Store Product',
    VAL_STORE_PROD_NOT_FOUND: 'Valid Store Product Cannot Be Found',
    ERR_EDIT_SEL_STORE_PROD:
        'Error Occurred While Editing the Selected Store Product',
    INV_STORE_DET_CHK_AGAIN: 'Invalid Store Details. Please Check Again.',
    SYS_USER_NOT_AVAIL: 'System User Is Not Available',
    INV_MON: 'Invalid Month',
    INV_YEAR: 'Invalid Year',
    INV_KEY: 'Invalid Key',
    IV_NOT_FOUND: 'IV Not Found',
    STORE_ID_NOT_FOUND: 'Cannot Find Store Id',
    ALREADY_ATTENDED_COMPL: 'Someone Already Attended to this Compliance!',
    INV_FIELD_ID: 'Invalid Field Id!',
    BRAND_NOT_FOUND: 'Cannot Find Brand',
    ERR_UPD_DEPT_IMPORT_CHANGES:
        'Error Occurred When Update Department With Import Changes.',
    SUPPLIER_NOT_FOUND: 'Cannot Find Supplier',
    SIM_SNAP_REMOVE_FAIL: 'Simulation Snapshot Remove Failed',
    SUBCAT_NOT_FOUND: 'Cannot Find Subcategory',
    IMG_UPLD_FAIL: 'Image Uploading Failed!',
    INV_ID: 'Invalid ID',
    INV_AUI_SUB_SET: 'Invalid AUI Subscription Settings!',
    AUI_ALRDY_CONV: 'AUI Already Converted!',
    AUI_CONV_LMT_REACHED: 'AUI Convertion Limit Reached!',
    NO_PAR_LVL_AVAIL_TKBK: 'No Parent Level Available for Takeback!',
    NO_CONN_DISC_JOB_REC_UPD: 'No Connect Disconnect Job Record to Update',
    FLD_NAME_ALRDY_IN_USE: 'Field Name Already in Use!',
    MISS_FLD_NAME: 'Missing Field Name!',
    FAIL_TO_SAVE_FLD: 'Failed To Save Field',
    CANNOT_FIND_FLD: 'Cannot Find Field',
    FLR_NAME_ALRDY_IN_USE: 'Floor Name Already in Use!',
    CANNOT_FIND_FLR_NAME: 'Cannot Find Floor Name',
    CANNOT_FIND_FLR: 'Cannot Find the Floor',
    CANNOT_FIND_IMG_NAME: 'Cannot Find Image Name',
    PLS_ATTACH_AI_IMG: 'Please Attach AI Images',
    MANDATORY_REMARK: 'Remark is Mandatory!',
    DEPT_CANNOT_BE_NULL: 'Department Cannot Be Null!',
    CAT_CANNOT_BE_NULL: 'Category Cannot Be Null!',
    DEPT_NAME_EMPTY_ADD_VALID:
        'Department Name is Empty. Please Add Valid Name.',
    DEPT_NAME_CANNOT_BE_NULL: 'Department Name Cannot Be Null!',
    INV_CHAIN_ID: 'Invalid Chain Id!',
    TAG_NAME_ALRDY_IN_USE: 'Tag Name Already In Use!',
    CANNOT_FIND_TAG_NAME: 'Cannot Find the Tag Name',
    NO_DATA_AVAILABLE: 'No Data Available!',
    SAVE_SIM_SNAPSHOT_BEFORE_IMPL:
        'Save Simulation Snapshot Before Implementation!',
    FLR_NOT_AVAIL_FOR_STORE: 'Floor Not Available for the Store!',
    STORE_MGR_NOT_AVAIL_FOR_STORE: 'Store Manager Not Available for the Store!',
    STORE_MGR_FLR_NOT_AVAIL_FOR_STORE:
        'Store Manager and Floor Not Available for the Store!',
    ACTIVE_PLG_CONTAIN_MULT_AISLES_CANNOT_PUSH:
        "Active Planogram Contain With Multiple Aisles, Can't Push!",
    SIM_CHANGE_DATA_INVALID: 'Simulation Changes Data Invalid!',
    EMAIL_IMG_INFO_NOT_FOUND: 'Email or Image Information Not Found!',
    MANY_DRAFT_VER_ACTIVE_LAYOUT_ONE_DRAFT_CONFIRM_VER:
        'There Have Many Draft Versions. When There Have Active Layout Version, Only Can Have One Drafting or Confirm Versions.',
    FLR_INFO_NOT_FOUND: 'Floor Information Not Found!',
    INV_FLOOR_ID: 'Invalid Floor Id!',
    FIELD_INFO_NOT_FOUND: 'Field Information Not Found!',
    LAYOUT_STATUS_ACTIVE_INACTIVE_CANNOT_MODIFY:
        'This Layout Status Is Active or Inactive. Therefore Cannot Modify',
    INV_FLOOR_LAYOUT_ID: 'Invalid Floor Layout Id!',
    ALRDY_UPDATE_ONGOING: 'Already Update is Ongoing!',
    NO_STORE_INFO_FOUND: 'No Store Info Found!',
    ADD_FLOOR_TO_TARGET_STORE: 'Please Add a Floor to the Target Store!',
    INV_FLOOR_AISLE_HAS_FIELD_ID: 'Invalid Floor Aisle Has Field Id!',
    DUPL_FIELD_NO_FOUND_IN_DEPT: 'Duplicate Field No Found in [$] Department.',
    INV_PROD_INFO: 'Invalid Product Information',
    DTO_MAPPING_FAILED: 'Dto Mapping Failed!',
    NO_CONFIG_FOUND_FOR_MEG_QUEUE: 'No Config Found for Message Queue!',
    REMOVE_FIELD_DUP: 'Finished Removing Field Duplicates',
    NO_MEDIA_COLLECTION_FOUND: 'No Media Collection Found!',
    ERR_SAVE_DEPT_DATA: 'Error Occurred While Saving Department Data',
    CANNOT_FIND_TAG: 'Cannot Find the Tag',
    INV_PLG_FIELD_ID: 'Invalid Planogram Field Id',
    NO_ITEM_FOUND: 'No Item Found!',
    GS1_STATUS_CODE: 'GS1 Status Code [$]',
    GS1_EXCEPTION: 'GS1 Exception!',
    CANNOT_FIND_PROD_INFO: 'Cannot Find Product Information',

    //translation codes from USER_MS
    SALE_IMPORT_BRANCH_CODE_EXISTS: 'Sale Import Branch Code Already Exists!',
    REGION_NAME_OR_CODE_EXISTS: 'Region Name or Code Already Exists!',
    REGION_HAS_BRANCHES_CANNOT_DEL:
        'This Region Has Branches, Unable to Delete!',
    GRP_USER_NOT_FOUND: 'Group User Not Found!',
    USER_HAS_ROLE_CANNOT_DEL: 'User Has Role, Unable to Delete',
    USER_NAME_ALREADY_EXISTS: 'User Name Already Exist!',
    INV_USER_INFO: 'Invalid User Infomation!',
    INV_CHAIN_INFO: 'Invalid Chain Information!',
    INV_REGION_INFO: 'Invalid Region Information!',
    INV_BRANCH_INFO: 'Invalid Branch Information',
    DEPT_NAME_IN_USE: 'Department Name Already in Use!',
    GRP_NAME_ALREADY_EXISTS: 'Group Name Already Have.',
    GRP_NOT_FOUND: 'Group Not Found!',
    INV_REGION_LIST: 'Region List Is Invalid!',
    INV_STORE_LIST: 'Store List Is Invalid!',
    STORE_CHAIN_INFO_NOT_FOUND: 'Store or Chain Information Not Found!',
    USER_NOT_FOUND: 'User Not Found!',
    DEVICE_TOKEN_NOT_FOUND: 'Device Token Not Found!',
    NO_LOWER_RANK_USERS: 'No Lower Rank Users!',
    PWD_NOT_MATCH_CONFIRM_PWD: 'New Password and Confirm Password Not Match!',
    USER_INFO_NOT_FOUND: 'User Information Not Found!',
    OLD_PWD_NOT_MATCH: 'Old Password Not Match!',
    UNAME_OR_PWD_INCORRECT: 'User Name or Password Incorrect!',
    STORE_CODE_EXISTS: 'Store Code Already Exists!',
    STORE_NAME_EXISTS: 'Store Name Already Exists!',

    //translation codes from NOTIFY_MS
    INV_MSG_SRC_INFO: 'Invalid Message Source Information!',
    DATA_NOT_FOUND: 'Data Not Found',

    //translation codes from TASK_MS
    CANT_TRANSF_TO_DTO_WITH_ERR_MSG: 'Cannot Transfer to DTO - [$]',
    TASK_ALLOC_NOT_FOUND: 'Task Allocation Not Found',
    TASK_ATTEND_DATA_NOT_FOUND: 'Task Attendance Data Not Found',
    TASK_ALLOC_DETAIL_ID_NOT_FOUND: 'Task Allocation Detail Id Not Found',
    ERR_OCCURED: 'Error Occured',
    ALLOC_DETAIL_ID_NOT_FOUND: 'Allocation Detail Id Not Found',
    DATA_CANNOT_TRANSF_TO_DTO: 'Data Cannot Transfer to DTO',
    FL_UUID_NOT_FOUND: 'Floor Layout Uuid Not Found',
    NOT_VALID_SEARCH_TYPE: 'Not a Valid Search Type',
    NO_USERS_SELECTED_REGIONS: 'There Have No Users for Selected Regions!',
    NO_USERS_SELECTED_STORE: 'There Have No Users for Selected Store!',
    NO_USERS_SELECTED_CHAIN_LEVEL:
        'There Have No Users for Selected Chain Level!',
    NO_TASK_SRC_INFO: 'No Task Source Information!',
    VAL_QNR_NOT_FOUND: 'Valid Questionnaire Cannot Be Found.',
    TASK_ALLOC_DETAILS_NOT_FOUND: 'Task Allocation Details Cannot Be Found.',
    TASK_NOT_FOUND: 'Task Not Found',
    INV_QNR_STATUS_CHK_AGAIN: 'Invalid  Question Status. Please Check Again.',
    INV_TASK_ATTEND_DETAILS: 'Invalid Task Attendance Details.',
    INV_ID_TASK_ATTEND_DETAILS: 'Invalid Id for Task Attendance Details.',
    INV_TASK_STATUS_ADD_UPD:
        'Invalid TASK STATUS While Adding or Updating the Task.',
    INV_TASK_ALLOC_DETAILS: 'Invalid Task Allocation Details.',
    INV_TASK_STATUS: 'Invalid Task Status',
    TASK_ALLOC_DETAILS_NOT_FOUND_QNR:
        'Task Allocation Details Cannot Be Found Questionnaire.',
    PENDING_SUB_TASKS_EXIST: 'There Have Pending Sub Task',
    NO_TASK_TO_APPROVE: 'No Task Found To Approve!',
    TASK_REPEAT_DETAILS_NOT_FOUND: 'Task Repeat Details Not Found!',
    INVALID_TASK_REPEAT_DATES:
        'Task Repeat Start Date or End Date Not Provide Correctly!',
    NO_TASK_TITLE_PROVIDED: 'No Task Title Provided!',
    NO_TASK_DESC_PROVIDED: 'No Task Description Provided!',
    TASK_APPROVERS_NOT_FOUND: 'Task Approvers Not Found!',
    DUP_ALLOC_USERS_VERIFY:
        'Allocation Users Are Duplicate.Please Verify the Allocation Users!',
    TASK_GRP_DETAILS_INVALID: 'Task Group Details Invalid!',
    NO_TASK_RECEIVERS_FOUND: 'No Task Receivers Found',
    TASK_REQ_FEEDBACK_NOT_FOUND: 'Task Request Feedback Not Found!',
    INV_QNR_DETAILS: 'Provided Questionnaire Details Are Invalid!',
    VAL_QNR_DETAILS_NOT_FOUND: 'Valid Questionnaire Details Cannot Be Found!',
    QNR_NEED_PUBLISHED:
        'Questionnaire Need to Be Published Before Use to Create Tasks!',
    FUTURE_TASK_ALREADY_ATTENDED: 'Some Future Task Already Attended',
    INV_TASK_DATES: 'Invalid Task Dates!',
    DATES_NOT_PROVIDED_CORRECTLY: 'Dates Not Provide Correctly!',
    INV_TASK_INFO: 'Invalid Task Information!',
    NO_TASK_INFO_FOUND: 'No Task Informaton Found!',
    INVALID_IMG_COUNT: 'Image Count Not Valid',
    TASK_ALREADY_ALLOCATED: 'This Task Allready Allocated!',
    CANT_SAVE_COMMENT_NO_PERMISSION:
        'Unable to Save Comment, Because You Are Not a Region Manager or Receiver',
    NO_REGION_MGR_FOR_TASK: 'No Region Manger Found for This Task',
    CANT_FIND_TASK_ALLOC_DETAIL: 'Cannot Find Any Task Allocation Detail!',
    NO_TASK_ALLOC_DETAIL_INFO: 'No Task Allocation Detail Information!',
    TASK_CAT_NAME_EMPTY: 'Task Category Name is Empty!',
    TASK_CREATED_APPROVED: 'The Task You Had Created Was Approved.',
    INVALID_ACTION_QNR: 'Invalid Action Question!',
    ERR_OCCURED_CHG_QNR_STATUS:
        'Error Occurred While Changing Status of Specific Questionnaire',
    ERR_OCCURED_CREATING_NEW_QN: 'Error Occurred While Creating New Question',
    VAL_QNR_NOT_FOUND_EDITED_QN:
        'Valid Questionnaire Cannot Be Found for This Edited Question. Please Check and Try Again',
    VAL_QNR_NOT_FOUND_EDIT_Q:
        'Valid Question Cannot Be Found. Please Check and Try Again',
    ERR_OCCURED_CREATING_NEW_QNR:
        'Error Occured While Creating New Questionnaire',
    ERR_OCCURED_UPDATING_QN: 'Error Occured While Updating Question',
    ERR_OCCURED_SAVE_QN_FEEDBACK:
        'Error Occured While Saving Question Feedback Types',
    INV_QN_FEEDBACK_TYPE_EDIT_Q:
        'Invalid Question Feedback Type While Editing a Question',
    CANT_EDIT_PUBLISHED_QNR:
        'You Cannot Edit This Published Questionnaire When Assigned to Tasks',
    ERR_OCCURED_UPDATING_SPECIFIC_QNR:
        'Error Occured While Updating Specific Questionnaire',
    ERR_OCCURED_CHG_QNR_STATUS_SPECIFIC:
        'Error Occured While Changing Status of Specific Questionnaire',
    CANT_DELETE_QNR_WITH_REFERENCES:
        'You Cannot Delete This Questionnaire Because References Are Available for Tasks',
    ERR_OCCURED_DELETING_SPECIFIC_QNR:
        'Error Occurred While Deleting Specific Questionnaire',
    CANT_CHANGE_QNR_STATUS_PUBLISHED:
        'You Cannot Change Questionnaire Status to PUBLISHED Without Any Valid Questions.',
    NAV_NOT_AVAILABLE_BRANCH_QN:
        'Navigations Not Available for Branch Questions.',
    ACT_OR_NAV_TYPES_NOT_AVAILABLE_BRANCH_QN:
        'Actions or Navigations Types Not Available for Branch Questions.',
    NAV_NOT_AVAILABLE_SOME_QN: 'Navigations Not Available for Some Questions.',
    ACTION_QN_NOT_FOUND: 'Action Question Not Found!',
    INVALID_ACTION_TYPE: 'Invalid Action Type!',
    ERR_OCCURED_PUBLISHING_SPECIFIC_QNR:
        'Error Occured While Publishing Specific Questionnaire',
    CANT_UNPUBLISH_QNR_WITH_REFERENCES:
        'You Cannot Unpublish This Questionnaire Because References Are Available for Tasks',
    ERR_OCCURED_ADDING_TASK_ATTEND_LOG:
        'Error Occured While Adding Task Attendance Log',
    INV_TASK_COMP_DATA_AVAIL:
        'Invalid Task Completion Data Available. Please Check and Try Again.',
    INV_TASK_COMP_OPT_DATA_AVAIL:
        'Invalid Task Completion Option Data Available. Please Check and Try Again.',
    ERR_OCCURED_DELETING_MEDIA_FILES:
        'Error Occured While Deleting Media Files.',
    ERR_OCCURED_CHG_STATUS_TASK_ALLOC_DETAILS:
        'Error Occured While Changing the Status of Task Allocation Details',
    TASK_ATTEND_LOG_NOT_FOUND: 'Task Attendance Log Cannot Be Found.',
    ERR_OCCURED_EDITING_TASK_COMP_DETAILS:
        'Error Occured While Editing Task Completion Details',
    ERR_OCCURED_SAVING: 'Error Occured While Saving',
    NO_FEEDBACK_TYPE_FOUND: 'No FeedbackType Found',
    NOT_CREATED_ANY_TASK_VERIFY_DATES:
        'Not Created Any Task! Please Verify Dates You Selected.',
    NO_USERS_IN_LIST: 'No User in List',
    STORE_MGR_NOT_FOUND: 'Store Manager Not Found',
    ALLOCATOR_DOES_NOT_EXIST: 'Allocator Does Not Exist',
    SOME_RECEIVERS_NOW_ALLOCATORS:
        'Some Task Receivers Are Now Task Allocators!',
    TASK_ALREADY_ATTENDED: 'Task Already Attdended!',
    REQ_FEEDBACK_OPTION_NOT_FOUND: 'Requested Feedback Option Not Found',
    REQ_FEEDBACK_NOT_FOUND: 'Requested Feedback Not Found',
    INV_TASK_ALLOC_DETAIL_INFO_TASK_APPROVED:
        'Invalid Task Allocation Detail Information or Task Is Already Approved Task!',
    DTO_MAP_FAILED: 'Dto Map Failed [$]',
    NO_CONFIG_MSG_QUEUE: 'No Config Found for Message Queue!',
    SALE_CYCLE_LOGIC: 'Sale Cycle Logic',
    THREEDQTY: '3D Quantity',
    SALEQTY: 'Sale Quantity',
    DAYCOUNT: 'Day Count',
    CAL_CHAIN: 'Calculated Using Chain data',
    INACTIVE: 'INACTIVE',
    MERGE: 'MERGE',
    Print_QR: 'Print QR',
    Fit_To_Width: 'Fit-To-Width',
    ZoomPan: 'Zoom-Pan',
    mark_stackable_onoff: 'Stackable OFF/ON',

    aristo: "Aristo's",
    RESULTSCOUNT_LIMIT: 'Results count limited for 3 numbers',
    TYPE_FIRSTACTION_HERE: 'Type the first action item here',
    TASK_IS_UPDATED: 'Task is updated!',
    TASK_APPROVED: 'Task Approved!',

    CONFIRMED: 'Confirmed',
    USER_NAME_IS_REQUIRED: 'User group name is required',
    ADD_ATLEASE_ONEUSER_REQUIRED: 'Add at least one user is required',
    AREYOUSURE_YOU_WANT_TO_DELETE: 'ARE YOU SURE YOU WANT TO DELETE?',
    THIS_WILL_DELETE_THE_VERSION: 'THIS WILL DELETE THE VERSION',
    DELETED_VERSION_SUCCESSFULLY: 'Deleted Version Successfully',
    MASTER_PLANOGRAM_DUPLICATED: 'Masterplanogram Duplicated',
    UPDATE_DEP_CHANGES_FIRST: 'Update department changes first',
    Enter_a_Version_Name: 'Enter a Version Name',
    CONFIRM_TO_RESTORE: 'Confirm to Restore',
    Successfully_disconnected: 'Successfully disconnected',
    PROFILE_INFO: 'Profile info',
    CHANGE_PROFILESETTING: 'Change Profile Settings',
    PROFILE: 'Profile',
    ENTER_TAG_NAME: 'Enter tag name',
    CANNOT_EXPAND_PROD_ANOTHER_EXIST:
        'Cannot expand product, another product already existing',
    CANNOT_CHANGEPOSITION_OF_MORETHANONE_SHELF:
        'Cannot change position of more than one shelf',
    NETWORK_ERROR: 'Network Error',

    GS1_CREDENTIAL_NOT_FOUND: 'GS1 Credentail not found !',
    RESET_AND_CONTINUE: 'Reset and continue',
    SELECTED_STORE_NEED_REST_BEFORE_SELECT_CURRENT_SIM:
        'Selected store(s) need to be reset before selecting the currently viewing simulation.',
    CANNOT_PROCEED_SELECTION_WITHA_RELOGRAM:
        "Can't proceed selection with a relogram !",
    USER_ROLL_INFORMATION_NOT_FOUND: 'User roll information Not Found !',
    IS_NOTIFICATION_ENABLE: 'Is Notification Enable',
    planograms_v2: 'Planograms_v2',
    VERSION_NAME: 'Version Name',
    FIELDS: 'Fileds',
    CHOSEN: 'chosen',
    MAKE_REPORT: 'Make Report',
    EXPORT_RESULT: 'Export results',
    PDF: 'Pdf',
    EXCELL: 'Excel',
    SHARE_VIA_EMAIL: 'Share via Email',
    IMPLEMENTATION_REPORT: 'Implementation report:',
    OTHER_REPORTS: 'Other reports:',
    NON_USED_PRODUCTS: 'Non used products',
    PRODUCT_LIST_FOR_ORDER: 'Products list for order',
    SEND_VIA_EMAIL: 'Send via Email',
    SEND_TO_STORES: 'Send to Stores',
    ADD_ME_TO_CC: 'Add me to CC',
    Inactive: 'In-active',
    IF_DELETE_REFERENCE_TASK_OR_THIS_ONLY:
        'If delete future tasks or this task only',
    REPEATED_TASK_ALERT: 'Repeted Task Alert',
    DO_YOU_WANT_TO_UPDATE_REFERENCE_TASK_OR_THIS_ONLY:
        'Do you want to update all task or this task only',
    STATUS_OF_THE_SINGLE_TASK: 'Status of the single task',
    STATUS_DATE: 'Status date',
    USER: 'User',
    Only_planograms: 'Only planograms',
    No_planograms: 'No planograms',
    selectuser: 'Select User',
    task_type: 'Task type',
    connected_store_list:'Connected store list',
    FIELD_LIST: 'Field List',
    SEARCH_FIELD: 'Search Field',
    ADVANCED_FILTER: 'Advanced Filter',
    CLEAR: 'Clear',
    FIELDS_WIDTH: 'Fields Width',
    FIELDS_COUNT: 'Fields Count',
    CONNECTED_STORES: 'Connected Stores',
    CONTAINED_BLOCKED_ORDER_PRODUCTS: 'Contains blocked for order products',
    CONTAINED_BLOCKED_SALES_PRODUCTS: 'Contains blocked for sales products',
    CONTAINED_ARCHIVED_PRODUCTS: 'Contains archived products',
    START_TYPING: 'Start typing',
    PLANOGRAM_THAT_CONTAIN: 'Planogram that contains...',
    NUM: 'Num',
    RESULTS_V2:'Results',
    DATA_LOADING:'Data Loading...',
    LAST_MODIFIED_AD: 'Last Modified',
    FIELDS_NUMBER: 'Fields Number',

    SET_CONNECTED_STORES_TO: 'Please set a To for Connected Stores',
    SET_CONNECTED_STORES_FROM: 'Please set a From for Connected Stores',
    SET_FIELDS_COUNT_TO: 'Please set a To for Fields Count',
    SET_FIELDS_COUNT_FROM: 'Please set a From for Fields Count',
    SET_FIELDS_WIDTH_TO: 'Please set a To for Fields Width',
    SET_FIELDS_WIDTH_FROM: 'Please set a From for Fields Width',
    PDF_SELECTED:'PDF is selected, Excel export will not run.',
    SELECT_BOTH_PDF_NON_USED_PRODUCT:"Please select both Excel and Non Used Products options.",
    SELECT_REPORT_TYPE:'Please select report type',
    dataPanel:"Data Panel",
    DATA_PANEL: {
        subcategory:"Sub Category",
        brand:"Brand",
        allProduct:'All products',
        product :"Product",
        supplier:"Supplier",
        subCat:"Sub Cat",
        tolerance:"Tolerance" ,
        rec: "Rec",
        allProducts:"All products",
        simulatedOnly:"Simulated only",
        notSimulated:"Not simulated"   

    },
    NON_USED_PRODUCTS:'Non used products',
    EXPORT_RESULT_IN_EXCEL:'export_result_in_excel',
    EXPORT_RESULT_IN_PDF:'export_result_in_pdf',
    TOO_MANY_KEY_PRESS_ERROR:"Too many key presses. Please wait.",
    LAST_MODIFIED:'Last modified',
    DEPARTEMENT_ALREADY_EXIST_MSG: 'Department already Exist!',

    Attributes:'Attributes',
    REGION_FIRST: 'Please select a region first.',
    BRANCH_FIRST: 'Please select a branch first.',
    DEPARTMENT_FIRST: 'Please select a department first.',
    SELECT_BRANCH:'Select Branch',
    SELECT_DEPT:'Select department',
    SELECT_VER:'Select Version',
    Inactive: 'Inactive',
    Pending: 'Pending',
    Active: 'Active',
    Active_Pending:'Active & Pending',

    INVALID_EMAIL_FORMAT: 'Invalid Email Format',
    EMAIL_IS_REQUIRED: 'Email is required',
    FILE_DOWNLOADED_SUCCESS_MESSAGE:'File downloaded successfully!',

    DEPARTMENT_IMPLEMENT: 'Department Implementation',
    CHATBOX_MANIPULATION: 'Manipulation',
    CHATBOX_MESSAGE_TO_MANIPULATION: 'Message to manipulation . . .',
    CHATBOX_AI_RESPONSE_RECEIVED: 'AI Response received',
    CHATBOX_SOMETHING_WENT_WRONG: 'Something went wrong. Please try again.',
    CHATBOX_WELCOME_MESSAGE: 'Hi! I\'m ready to help with planogram manipulation. Please provide your instruction.!',
    SELL_PRICE: 'Sell Price',

    POS_CODE: 'POS Code',
    Active_Pending : 'Pending & Active',
    ITEM_SELECTED: 'Item selected',
    ITEMS_SELECTED: 'Items Selected' ,
    ERROR_CONNECTED_STORES: "Error Connected Stores FROM value can't be greater than TO value!",
    ERROR_FIELD_COUNT: "Error Field Count FROM value can't be greater than TO value !",
    ERROR_FIELD_WIDTH: "Error Field Width FROM value can't be greater than TO value !",
    UNRESOLVECONFIRM :"Are you sure to unresolve selected items?",
    ALL_COUNT_FILEE_RROR :"Number of all the errors when importing.",
    FILES_CORRUPTED_FILE_ERROR :"Number of files corrupted when importing.",
    DATA_ERRORS_FILE_ERROR :"Number of data error files when importing.",
    SAVE_ERRORS_FILE_ERROR :"Number of errors when saving.",
    RESOLVE: 'Resolve',
    AND:'and',
    EXPAND_NUMBER: 'Expand Number',
    CANNOT_REMOVE_MORE_PRODUCTS_THAN_AVALIABLE: 'Cannot remove more products than available in the selected block.',
    SENT_DATE: 'Sent Date',
    DEP_CODE: 'Dep Code',
    SENT_TO: 'Sent To',
    LAST_SENT: 'Last Sent',
    REGION_CODE: 'Region Code',
    REGION_NAME: 'Region Name',
    AND:'and',
    BY_STORE: 'By Store',
    BY_PLANOGRAM: 'By Planogram',
    DETAILED: 'Detailed',
    SENT_DATE_TO_VALIDATION : 'Please set a To date for sent date',
    SENT_DATE_FROM_VALIDATION : 'Please set a From date for sent date',
    PLEASE_ENTER_INTEGER_VALUE: 'Please enter an integer value',
    PLEASE_ENTER_VALID_NUMBER: 'Please enter a valid number',
}
